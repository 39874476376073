import { FC } from 'react';
import { authRoutes } from '../auth/router';
import { postsRoutes } from '../posts/router';
import ContactView from './views/contact';
import PrivacyView from './views/privacy.view';
import RootView from './views/root.view';
import TermsView from './views/terms.view';

export interface Route {
    key: string;
    title: string;
    path: string;
    enabled: boolean;
    component: FC;
}

export const appRoutes: Array<Route> = [
    // app
    {
        key: 'root',
        title: 'Mist',
        path: '/',
        enabled: true,
        component: RootView,
    },
    {
        key: 'terms',
        title: 'Terms of Service',
        path: '/terms',
        enabled: true,
        component: TermsView,
    },
    {
        key: 'privacy',
        title: 'Privacy Policy',
        path: '/privacy',
        enabled: true,
        component: PrivacyView,
    },
    {
        key: 'contact',
        title: 'Contact Us',
        path: '/contact',
        enabled: true,
        component: ContactView,
    },
    // auth
    ...authRoutes,
    // posts
    ...postsRoutes,
];
