import axios from 'axios';
import { BACKEND_URL } from '../app/config/app.constants';
import EditPasswordDto from './dtos/edit-password.dto';

export default class AuthService {
    PATH = 'auth';

    async processEmailEdit(accessToken: string): Promise<void> {
        const url = `${BACKEND_URL}/${this.PATH}/email`;

        await axios.patch(url, undefined, {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        });
    }

    async processPasswordEdit(accessToken: string, dto: EditPasswordDto) {
        const url = `${BACKEND_URL}/${this.PATH}/password`;

        await axios.patch(url, dto, {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        });
    }
}
