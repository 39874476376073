import { Route } from '../app/router';
import EmailUpdateView from './views/email-update.view';
import PassUpdateView from './views/pass-update.view';

export const authRoutes: Array<Route> = [
    // auth
    {
        key: 'update-email',
        title: 'Update Email',
        path: '/auth/email/:token',
        enabled: true,
        component: EmailUpdateView,
    },
    {
        key: 'update-password',
        title: 'Update Password',
        path: '/auth/password/:token',
        enabled: true,
        component: PassUpdateView,
    },
];
