import InstagramIcon from '@mui/icons-material/Instagram';
import RedditIcon from '@mui/icons-material/Reddit';
import TwitterIcon from '@mui/icons-material/Twitter';
import { Box, ImageList, ImageListItem, Link, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { useEffect, useState } from 'react';
import { APP_STORE_URL } from '../config/app.constants';
import AppIconPng from '../resources/images/app_icon.png';
import Image2 from '../resources/images/iphone_generate_preview.jpg';
import Image5 from '../resources/images/iphone_generation_preview.jpg';
import Image3 from '../resources/images/iphone_models_preview.jpg';
import Image4 from '../resources/images/iphone_prompt_studio_preview.jpg';
import DownloadOnAppStoreSvg from '../resources/svgs/download_on_app_store.svg';
import { fontSize } from '../util/preferences';

export default function RootView() {
    const previewImageUrls: string[] = [
        'https://f004.backblazeb2.com/b2api/v1/b2_download_file_by_id?fileId=4_z87ed1cb9e69350448f4a0017_f108dedd3b826bc00_d20230327_m181736_c004_v0402003_t0016_u01679941056056',
        'https://f004.backblazeb2.com/b2api/v1/b2_download_file_by_id?fileId=4_z87ed1cb9e69350448f4a0017_f117209e1993bc778_d20230327_m172637_c004_v0402005_t0045_u01679937997474',
        'https://f004.backblazeb2.com/b2api/v1/b2_download_file_by_id?fileId=4_z87ed1cb9e69350448f4a0017_f1167d14b0f9c5d0e_d20230327_m012949_c004_v0402015_t0028_u01679880589835',
        'https://f004.backblazeb2.com/b2api/v1/b2_download_file_by_id?fileId=4_z87ed1cb9e69350448f4a0017_f105d721347d8c73c_d20230327_m171740_c004_v0402011_t0051_u01679937460084',
        'https://f004.backblazeb2.com/b2api/v1/b2_download_file_by_id?fileId=4_z87ed1cb9e69350448f4a0017_f114142651a2d265e_d20230327_m162101_c004_v0402015_t0047_u01679934061320',
        'https://f004.backblazeb2.com/b2api/v1/b2_download_file_by_id?fileId=4_z87ed1cb9e69350448f4a0017_f118cc906405fb3ea_d20230327_m160255_c004_v0402013_t0047_u01679932975005',
        'https://f004.backblazeb2.com/b2api/v1/b2_download_file_by_id?fileId=4_z87ed1cb9e69350448f4a0017_f10523e3486b01526_d20230326_m232346_c004_v0402007_t0012_u01679873026263',
        'https://f004.backblazeb2.com/b2api/v1/b2_download_file_by_id?fileId=4_z87ed1cb9e69350448f4a0017_f105586b0fece0eba_d20230327_m155706_c004_v0402008_t0027_u01679932626536',
        'https://f004.backblazeb2.com/b2api/v1/b2_download_file_by_id?fileId=4_z87ed1cb9e69350448f4a0017_f10636c0d8ed190cb_d20230327_m110235_c004_v0402002_t0007_u01679914955589',
        'https://f004.backblazeb2.com/b2api/v1/b2_download_file_by_id?fileId=4_z87ed1cb9e69350448f4a0017_f1123a252b0463e26_d20230327_m061452_c004_v0402014_t0016_u01679897692229',
        'https://f004.backblazeb2.com/b2api/v1/b2_download_file_by_id?fileId=4_z87ed1cb9e69350448f4a0017_f1176ebad30238482_d20230327_m061145_c004_v0402008_t0006_u01679897505904',
        'https://f004.backblazeb2.com/b2api/v1/b2_download_file_by_id?fileId=4_z87ed1cb9e69350448f4a0017_f109f51da24b1ce19_d20230327_m054749_c004_v0402013_t0006_u01679896069717',
        // 'https://f004.backblazeb2.com/b2api/v1/b2_download_file_by_id?fileId=4_z87ed1cb9e69350448f4a0017_f117d964169d23790_d20221129_m030206_c004_v0402010_t0048_u01669690926995',
        // 'https://f004.backblazeb2.com/b2api/v1/b2_download_file_by_id?fileId=4_z87ed1cb9e69350448f4a0017_f107ba970eccad709_d20221129_m030206_c004_v0402006_t0038_u01669690926996',
        // 'https://f004.backblazeb2.com/b2api/v1/b2_download_file_by_id?fileId=4_z87ed1cb9e69350448f4a0017_f1120c393b3aadbad_d20221129_m043229_c004_v0402001_t0001_u01669696349937',
        // 'https://f004.backblazeb2.com/b2api/v1/b2_download_file_by_id?fileId=4_z87ed1cb9e69350448f4a0017_f1170cc66303ec466_d20221129_m043229_c004_v0402014_t0046_u01669696349939',
        // 'https://f004.backblazeb2.com/b2api/v1/b2_download_file_by_id?fileId=4_z87ed1cb9e69350448f4a0017_f100ca84838a08c44_d20221129_m043229_c004_v0402000_t0008_u01669696349932',
        // 'https://f004.backblazeb2.com/b2api/v1/b2_download_file_by_id?fileId=4_z87ed1cb9e69350448f4a0017_f1199cf9affb70fad_d20221129_m070247_c004_v0402014_t0059_u01669705367905',
        // 'https://f004.backblazeb2.com/b2api/v1/b2_download_file_by_id?fileId=4_z87ed1cb9e69350448f4a0017_f1148b6536249a5aa_d20221129_m161238_c004_v0402013_t0046_u01669738358990',
        // 'https://f004.backblazeb2.com/b2api/v1/b2_download_file_by_id?fileId=4_z87ed1cb9e69350448f4a0017_f119d793c9b9a2289_d20221129_m161239_c004_v0402007_t0034_u01669738359005',
        // 'https://f004.backblazeb2.com/b2api/v1/b2_download_file_by_id?fileId=4_z87ed1cb9e69350448f4a0017_f11777b8ebaddb12a_d20221129_m205236_c004_v0402003_t0018_u01669755156718',
        // 'https://f004.backblazeb2.com/b2api/v1/b2_download_file_by_id?fileId=4_z87ed1cb9e69350448f4a0017_f10040ffc48f3fca7_d20221129_m205236_c004_v0402012_t0050_u01669755156741',
        // 'https://f004.backblazeb2.com/b2api/v1/b2_download_file_by_id?fileId=4_z87ed1cb9e69350448f4a0017_f100f8625a2e5f66c_d20221129_m212126_c004_v0402014_t0009_u01669756886863',
        // 'https://f004.backblazeb2.com/b2api/v1/b2_download_file_by_id?fileId=4_z87ed1cb9e69350448f4a0017_f10040ffc4902c500_d20221130_m034632_c004_v0402012_t0040_u01669779992551',
        // 'https://f004.backblazeb2.com/b2api/v1/b2_download_file_by_id?fileId=4_z87ed1cb9e69350448f4a0017_f111ab1fd26321c3e_d20221130_m034951_c004_v0402010_t0027_u01669780191116',
        // 'https://f004.backblazeb2.com/b2api/v1/b2_download_file_by_id?fileId=4_z87ed1cb9e69350448f4a0017_f1019de98138c7853_d20221130_m091606_c004_v0402006_t0057_u01669799766869',
        // 'https://f004.backblazeb2.com/b2api/v1/b2_download_file_by_id?fileId=4_z87ed1cb9e69350448f4a0017_f1066d5b8d28d03a0_d20221130_m145051_c004_v0402009_t0017_u01669819851425',
        // 'https://f004.backblazeb2.com/b2api/v1/b2_download_file_by_id?fileId=4_z87ed1cb9e69350448f4a0017_f1177d954272cd555_d20221130_m212220_c004_v0402012_t0000_u01669843340387',
        // 'https://f004.backblazeb2.com/b2api/v1/b2_download_file_by_id?fileId=4_z87ed1cb9e69350448f4a0017_f1035b07c218c0bfc_d20221201_m190124_c004_v0402013_t0018_u01669921284229',
        // 'https://f004.backblazeb2.com/b2api/v1/b2_download_file_by_id?fileId=4_z87ed1cb9e69350448f4a0017_f10948eff2a21be28_d20221202_m032248_c004_v0402006_t0023_u01669951368388',
    ];

    const [screenSize, getDimension] = useState({
        dynamicWidth: window.innerWidth,
        dynamicHeight: window.innerHeight,
    });
    const setDimension = () => {
        getDimension({
            dynamicWidth: window.innerWidth,
            dynamicHeight: window.innerHeight,
        });
    };

    useEffect(() => {
        window.addEventListener('resize', setDimension);

        return () => {
            window.removeEventListener('resize', setDimension);
        };
    }, [screenSize]);

    return (
        <Box flexGrow={1} display={'flex'} justifyContent={'center'} alignItems={'top'}>
            <Stack width={1440} maxWidth={1440} m={2} spacing={0}>
                {renderHeaderComponent()}

                {renderFeaturesComponent()}

                {renderCommunityComponent()}

                {renderFooterComponent()}
            </Stack>
        </Box>
    );

    function renderHeaderComponent() {
        return (
            <Box>
                <Typography
                    variant={'h1'}
                    textAlign={'center'}
                    fontWeight={'bold'}
                    marginTop={8}
                    flex={1}
                >
                    Mist
                </Typography>

                <Typography
                    variant={'h3'}
                    textAlign={'center'}
                    fontWeight={'bold'}
                    marginTop={2}
                    flex={1}
                >
                    Turn your ideas into art
                </Typography>

                {renderDownloadOnStoreComponent()}
            </Box>
        );
    }

    function renderFeaturesComponent() {
        return (
            <Stack
                direction={'column'}
                alignItems={'normal'}
                justifyContent={'normal'}
                spacing={4}
                marginTop={8}
            >
                <Stack
                    direction={'row'}
                    alignItems={'center'}
                    justifyContent={'start'}
                    spacing={2}
                    marginTop={8}
                >
                    <img
                        src={Image2}
                        alt={'Preview for generating images'}
                        loading={'lazy'}
                        style={{
                            borderRadius: 32,
                            minWidth: 64,
                            maxWidth:
                                screenSize.dynamicWidth >= 480 ? 480 : screenSize.dynamicWidth,
                            flex: 1,
                        }}
                    />
                    {screenSize.dynamicWidth >= 720 ? (
                        <Typography
                            variant={'h4'}
                            textAlign={'end'}
                            fontWeight={'bold'}
                            minWidth={0}
                            flex={1}
                        >
                            Generate unique images by simply entering text<br></br>
                            <br></br>
                            Mist will process your request and send you a notification when the
                            images are ready!
                        </Typography>
                    ) : null}
                </Stack>

                <Stack
                    direction={'row'}
                    alignItems={'center'}
                    justifyContent={'end'}
                    spacing={2}
                    marginTop={0}
                >
                    {screenSize.dynamicWidth >= 720 ? (
                        <Typography
                            variant={'h4'}
                            textAlign={'start'}
                            fontWeight={'bold'}
                            minWidth={0}
                            flex={1}
                        >
                            Use some of the most popular AI models<br></br>
                            <br></br>
                            Each having different art styles
                        </Typography>
                    ) : null}

                    <img
                        src={Image3}
                        alt={'Preview of AI models'}
                        loading={'lazy'}
                        style={{
                            borderRadius: 32,
                            minWidth: 64,
                            maxWidth:
                                screenSize.dynamicWidth >= 480 ? 480 : screenSize.dynamicWidth,
                            flex: 1,
                        }}
                    />
                </Stack>

                <Stack
                    direction={'row'}
                    alignItems={'center'}
                    justifyContent={'start'}
                    spacing={2}
                    marginTop={0}
                >
                    <img
                        src={Image4}
                        alt={'Preview of Prompt Studio'}
                        loading={'lazy'}
                        style={{
                            borderRadius: 32,
                            minWidth: 64,
                            maxWidth:
                                screenSize.dynamicWidth >= 480 ? 480 : screenSize.dynamicWidth,
                            flex: 1,
                        }}
                    />
                    {screenSize.dynamicWidth >= 720 ? (
                        <Typography
                            variant={'h4'}
                            textAlign={'end'}
                            fontWeight={'bold'}
                            minWidth={0}
                            flex={1}
                        >
                            Use the Prompt Studio to easily add styles to your prompts
                        </Typography>
                    ) : null}
                </Stack>

                <Stack
                    direction={'row'}
                    alignItems={'center'}
                    justifyContent={'end'}
                    spacing={2}
                    marginTop={0}
                >
                    {screenSize.dynamicWidth >= 720 ? (
                        <Typography
                            variant={'h4'}
                            textAlign={'start'}
                            fontWeight={'bold'}
                            minWidth={0}
                            flex={1}
                        >
                            Get stunning results with little effort!
                        </Typography>
                    ) : null}

                    <img
                        src={Image5}
                        alt={'Preview of generation result'}
                        loading={'lazy'}
                        style={{
                            borderRadius: 32,
                            minWidth: 64,
                            maxWidth:
                                screenSize.dynamicWidth >= 480 ? 480 : screenSize.dynamicWidth,
                            flex: 1,
                        }}
                    />
                </Stack>
            </Stack>
        );
    }

    function renderCommunityComponent() {
        return (
            <Box marginTop={32}>
                <Typography
                    variant={'h3'}
                    textAlign={'center'}
                    fontWeight={'bold'}
                    marginBottom={4}
                >
                    Join the community
                </Typography>

                <ImageList variant="masonry" cols={3} gap={8}>
                    {previewImageUrls.map((url) => (
                        <ImageListItem key={url}>
                            <img src={`${url}`} alt={'Preview'} loading="lazy" />
                        </ImageListItem>
                    ))}
                </ImageList>
            </Box>
        );
    }

    function renderFooterComponent() {
        return (
            <Stack
                direction={'column'}
                alignItems={'center'}
                justifyContent={'center'}
                spacing={0}
                marginTop={32}
            >
                <Typography variant={'h3'} textAlign={'center'} fontWeight={'bold'} flex={1}>
                    Download the app to get started!
                </Typography>

                {renderDownloadOnStoreComponent()}

                <Stack
                    direction={'row'}
                    alignItems={'center'}
                    justifyContent={'center'}
                    spacing={4}
                    marginTop={32}
                >
                    <Link href={'https://twitter.com/mist_stability'} color={'inherit'}>
                        <TwitterIcon />
                    </Link>

                    <Link href={'https://www.reddit.com/r/mist_stability'} color={'inherit'}>
                        <RedditIcon />
                    </Link>

                    <Link href={'https://www.instagram.com/mist_stability'} color={'inherit'}>
                        <InstagramIcon />
                    </Link>
                </Stack>

                <Typography
                    textAlign={'center'}
                    fontSize={fontSize.sub}
                    fontWeight={'bold'}
                    color={'text.secondary'}
                    marginTop={4}
                >
                    Copyright &copy; 2023
                </Typography>

                <Box marginTop={8} />
            </Stack>
        );
    }

    function renderDownloadOnStoreComponent() {
        return (
            <Stack
                direction={'row'}
                alignItems={'center'}
                justifyContent={'center'}
                spacing={2}
                marginTop={8}
            >
                <Link href={APP_STORE_URL} display={'flex'} align={'center'}>
                    <img
                        src={AppIconPng}
                        alt="Mist app logo"
                        width={80}
                        height={80}
                        style={{ borderRadius: 8 }}
                    />
                </Link>

                <Link href={APP_STORE_URL} display={'flex'} align={'center'}>
                    <img
                        src={DownloadOnAppStoreSvg}
                        alt="Mist App Store download link"
                        height={64}
                    />
                </Link>
            </Stack>
        );
    }
}
