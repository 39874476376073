import { Box, Stack, Typography } from '@mui/material';
import TeddyBearSvg from '../resources/svgs/teddy_bear.svg';
import { fontSize } from '../util/preferences';

export default function NotFoundView() {
    return (
        <Box
            sx={{
                flexGrow: 1,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Stack
                sx={{
                    width: '0%',
                    maxWidth: 480,
                    flexGrow: 1,
                    bgcolor: 'primary.light',
                    borderRadius: '12px',
                    p: 2,
                    m: 2,
                }}
                spacing={2}
            >
                <img src={TeddyBearSvg} alt="" />

                <Typography sx={{ fontSize: fontSize.headline }} color="text.primary">
                    Page not found
                </Typography>
            </Stack>
        </Box>
    );
}
