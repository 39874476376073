import { Box, Link, Stack, Typography } from '@mui/material';
import ContactSvg from '../../app/resources/svgs/contact.svg';
import { fontSize } from '../util/preferences';

export default function ContactView() {
    return (
        <Box
            sx={{
                flexGrow: 1,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Stack
                sx={{
                    width: '0%',
                    maxWidth: 480,
                    flexGrow: 1,
                    bgcolor: 'primary.light',
                    borderRadius: '12px',
                    p: 2,
                    m: 2,
                }}
                spacing={2}
            >
                <img src={ContactSvg} alt="" />

                <Typography sx={{ fontSize: fontSize.headline }} color="text.primary">
                    Contact Us
                </Typography>

                <Link
                    href="mailto:support@mist.greenknightlabs.com"
                    color="text.secondary"
                    sx={{ fontSize: fontSize.body }}
                >
                    support@mist.greenknightlabs.com
                </Link>

                <Link
                    href="mailto:feedback@mist.greenknightlabs.com"
                    color="text.secondary"
                    sx={{ fontSize: fontSize.body }}
                >
                    feedback@mist.greenknightlabs.com
                </Link>
            </Stack>
        </Box>
    );
}
