import { Box, Button, Stack, Typography } from '@mui/material';
import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import ApiError from '../../app/interfaces/api-error.interface';
import ConfettiSvg from '../../app/resources/svgs/confetti.svg';
import UpdateEmailSvg from '../../app/resources/svgs/update_email.svg';
import { fontSize } from '../../app/util/preferences';
import AuthService from '../auth.service';

export default function EmailUpdateView() {
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [completed, setCompleted] = useState(false);

    useEffect(() => {
        processEmailEdit();
    }, []);

    return (
        <Box
            sx={{
                flexGrow: 1,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            {!completed ? renderEmailUpdateComponent() : renderCompletedComponent()}
        </Box>
    );

    function renderEmailUpdateComponent() {
        return (
            <Stack
                sx={{
                    width: '0%',
                    maxWidth: 480,
                    flexGrow: 1,
                    bgcolor: 'primary.light',
                    borderRadius: '12px',
                    p: 2,
                    m: 2,
                }}
                spacing={2}
            >
                <img src={UpdateEmailSvg} alt="" />

                {isLoading ? (
                    <Typography sx={{ fontSize: fontSize.headline }} color="text.primary">
                        Updating email...
                    </Typography>
                ) : null}

                {errorMessage ? (
                    <Typography sx={{ fontSize: fontSize.body }} color="error.main">
                        {errorMessage}
                    </Typography>
                ) : null}

                {!isLoading && errorMessage ? (
                    <Button
                        variant="text"
                        sx={{ color: 'text.primary' }}
                        onClick={processEmailEdit}
                    >
                        Try again
                    </Button>
                ) : null}
            </Stack>
        );
    }

    function renderCompletedComponent() {
        return (
            <Stack
                sx={{
                    width: '0%',
                    maxWidth: 480,
                    flexGrow: 1,
                    bgcolor: 'primary.light',
                    borderRadius: '12px',
                    p: 2,
                    m: 2,
                }}
                spacing={2}
            >
                <img src={ConfettiSvg} alt="" />

                <Typography sx={{ fontSize: fontSize.headline }} color="text.primary">
                    Successfully updated email!
                </Typography>

                <Typography sx={{ fontSize: fontSize.body }} color="text.secondary">
                    You can now close this page.
                </Typography>
            </Stack>
        );
    }

    async function processEmailEdit() {
        const authService = new AuthService();
        const address = window.location.href;
        const accessToken = address.split('/').pop();

        if (!accessToken) {
            return setErrorMessage('Invalid token.');
        }

        try {
            setIsLoading(true);
            await authService.processEmailEdit(accessToken);
            setCompleted(true);
        } catch (e) {
            setIsLoading(false);

            if (e instanceof AxiosError) {
                if (e.response && e.response.data) {
                    setErrorMessage((e.response.data as ApiError).message);
                } else {
                    setErrorMessage(e.message);
                }
            } else {
                console.error(e);
                setErrorMessage('An error occurred.');
            }
        }
    }
}
