import { createTheme } from '@mui/material';

export const themeMidnight = createTheme({
    palette: {
        primary: {
            light: '#252B40',
            main: '#1b2030',
            dark: '#151927',
            contrastText: '#FFF',
        },
        background: {
            default: '#1b2030',
            paper: '#252B40',
        },
        // secondary: {
        //     light: '#252B40',
        //     main: '#1b2030',
        //     dark: '#151927',
        //     contrastText: '#FFF',
        // },
        text: {
            primary: '#FFFFFF',
            secondary: '#9A9EBB',
            disabled: '#9A9EBB',
        },
        info: {
            main: '#9A9EBB',
        },
        error: {
            main: '#E24F48',
        },
        warning: {
            main: '#E3A04E',
        },
        success: {
            main: '#5C9DDD',
        },
    },
    // typography: {
    //     fontFamily: [
    //         '-apple-system',
    //         'BlinkMacSystemFont',
    //         '"Segoe UI"',
    //         'Roboto',
    //         '"Helvetica Neue"',
    //         'Arial',
    //         'sans-serif',
    //         '"Apple Color Emoji"',
    //         '"Segoe UI Emoji"',
    //         '"Segoe UI Symbol"',
    //     ].join(','),
    // },
});
