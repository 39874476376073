import { Box, Button, ImageList, ImageListItem, Stack, Typography } from '@mui/material';
import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import SharedFromMistComponent from '../../app/components/shared-from-mist.component';
import ApiError from '../../app/interfaces/api-error.interface';
import { fontSize } from '../../app/util/preferences';
import Post from '../models/post.model';
import PostsService from '../posts.service';

export default function PostView() {
    const [post, setPost] = useState<Post | undefined>(undefined);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        loadPost();
    }, []);

    return (
        <Box flexGrow={1} display={'flex'} justifyContent={'center'} alignItems={'top'}>
            <Stack width={1080} maxWidth={1080} m={2} spacing={2}>
                {SharedFromMistComponent()}

                {!post ? renderLoadingComponent() : renderPostComponent()}
            </Stack>
        </Box>
    );

    function renderPostComponent() {
        return (
            <Stack direction={'column'}>
                {post?.title ? (
                    <Typography fontSize={fontSize.body} color="text.secondary">
                        {post.title}
                    </Typography>
                ) : null}

                {post?.user_displayname ? (
                    <Typography fontSize={fontSize.body} color="text.secondary">
                        {post?.user_displayname ? `Made by ${post.user_displayname}` : ''}
                    </Typography>
                ) : null}

                {post?.media ? (
                    <ImageList cols={1} gap={12}>
                        {post.media.map((media) => (
                            <ImageListItem key={media.url}>
                                <img
                                    src={`${media.url}`}
                                    alt={post.title}
                                    loading="lazy"
                                    style={{ borderRadius: 8 }}
                                />
                            </ImageListItem>
                        ))}
                    </ImageList>
                ) : null}
            </Stack>
        );
    }

    function renderLoadingComponent() {
        return (
            <Stack direction={'column'} alignItems={'center'} justifyContent={'center'} spacing={2}>
                {isLoading ? (
                    <Typography fontSize={fontSize.headline} color="text.primary" m={32}>
                        Loading...
                    </Typography>
                ) : null}

                {errorMessage ? (
                    <Typography fontSize={fontSize.body} color="error.main" marginTop={32}>
                        {errorMessage}
                    </Typography>
                ) : null}

                {!isLoading && errorMessage ? (
                    <Button variant="text" color="inherit" onClick={loadPost}>
                        Try again
                    </Button>
                ) : null}
            </Stack>
        );
    }

    async function loadPost() {
        const postsService = new PostsService();
        const address = window.location.href;
        const id = address.split('/').pop();

        if (!id) {
            return setErrorMessage('Invalid id.');
        }

        setErrorMessage('');

        try {
            setIsLoading(true);
            const post = await postsService.getPostById(id);
            setPost(post);
            setIsLoading(false);
        } catch (e) {
            setIsLoading(false);

            if (e instanceof AxiosError) {
                if (e.response && e.response.data) {
                    setErrorMessage((e.response.data as ApiError).message);
                } else {
                    setErrorMessage(e.message);
                }
            } else {
                console.error(e);
                setErrorMessage('An error occurred.');
            }
        }
    }
}
