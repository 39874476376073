import { Box, CssBaseline, ThemeProvider } from '@mui/material';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { appRoutes } from './router';
import { theme } from './util/preferences';
import NotFoundView from './views/not-found.view';

function App() {
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Box height="100vh" display="flex" flexDirection="column">
                <BrowserRouter>
                    {/* <NavBar /> */}
                    <Routes>
                        {appRoutes.map((route) => (
                            <Route
                                key={route.key}
                                path={route.path}
                                element={<route.component />}
                            />
                        ))}
                        <Route path="*" element={NotFoundView()} />
                    </Routes>
                </BrowserRouter>
            </Box>
        </ThemeProvider>
    );
}

export default App;
