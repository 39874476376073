import { Box, Button, LinearProgress, Stack, TextField, Typography } from '@mui/material';
import { AxiosError } from 'axios';
import React, { useEffect, useState } from 'react';
import ApiError from '../../app/interfaces/api-error.interface';
import ConfettiSvg from '../../app/resources/svgs/confetti.svg';
import UpdatePasswordSvg from '../../app/resources/svgs/update_password.svg';
import { fontSize } from '../../app/util/preferences';
import AuthService from '../auth.service';
import EditPasswordDto from '../dtos/edit-password.dto';

export default function PassUpdateView() {
    const authService = new AuthService();
    const [accessToken, setAccessToken] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [newPasswordReenter, setNewPasswordReenter] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [completed, setCompleted] = useState(false);

    useEffect(() => {
        const address = window.location.href;
        const accessToken = address.split('/').pop();

        if (accessToken) {
            setAccessToken(accessToken);
        }
    }, []);

    return (
        <Box
            sx={{
                flexGrow: 1,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            {!completed ? renderUpdatePasswordComponent() : renderCompletedComponent()}
        </Box>
    );

    function renderUpdatePasswordComponent() {
        return (
            <Stack
                sx={{
                    width: '0%',
                    maxWidth: 480,
                    flexGrow: 1,
                    bgcolor: 'primary.light',
                    borderRadius: '12px',
                    p: 2,
                    m: 2,
                }}
                spacing={2}
            >
                <img src={UpdatePasswordSvg} alt="" />

                <Typography sx={{ fontSize: fontSize.headline }} color="text.primary">
                    Enter your new password
                </Typography>

                <TextField label="New password" type="password" onChange={onChangeNewPassword} />

                <TextField
                    label="Re-enter password"
                    type="password"
                    onChange={onChangeNewPasswordReenter}
                />

                {errorMessage ? (
                    <Typography sx={{ fontSize: fontSize.body }} color="error.main">
                        {errorMessage}
                    </Typography>
                ) : null}

                {!isLoading ? (
                    <Button
                        variant="text"
                        sx={{ color: 'text.primary' }}
                        onClick={processPasswordEdit}
                    >
                        Update password
                    </Button>
                ) : (
                    <Typography sx={{ fontSize: fontSize.headline }} color="text.primary">
                        Updating password...
                    </Typography>
                )}

                {isLoading ? <LinearProgress /> : null}
            </Stack>
        );
    }

    function renderCompletedComponent() {
        return (
            <Stack
                sx={{
                    width: '0%',
                    maxWidth: 480,
                    flexGrow: 1,
                    bgcolor: 'primary.light',
                    borderRadius: '12px',
                    p: 2,
                    m: 2,
                }}
                spacing={2}
            >
                <img src={ConfettiSvg} alt="" />

                <Typography sx={{ fontSize: fontSize.headline }} color="text.primary">
                    Successfully updated password!
                </Typography>

                <Typography sx={{ fontSize: fontSize.body }} color="text.secondary">
                    You can now close this page.
                </Typography>
            </Stack>
        );
    }

    function onChangeNewPassword(event: React.ChangeEvent<HTMLInputElement>) {
        setNewPassword(event.target.value);
    }

    function onChangeNewPasswordReenter(event: React.ChangeEvent<HTMLInputElement>) {
        setNewPasswordReenter(event.target.value);
    }

    async function processPasswordEdit() {
        if (newPassword.match(/^(.{0,7}|[^0-9]*|[^A-Z]*|[^a-z]*|[a-zA-Z0-9]*)$/g)) {
            return setErrorMessage(
                'Password must include at least: 8 characters, 1 uppercase, 1 lowercase, 1 number, and 1 special character.',
            );
        }

        if (newPassword !== newPasswordReenter) {
            return setErrorMessage('Passwords must match.');
        }

        if (!accessToken) {
            return setErrorMessage('Invalid token.');
        }

        setErrorMessage('');

        const dto: EditPasswordDto = {
            password: newPassword,
        };

        try {
            setIsLoading(true);
            await authService.processPasswordEdit(accessToken, dto);
            setCompleted(true);
        } catch (e) {
            setIsLoading(false);

            if (e instanceof AxiosError) {
                if (e.response && e.response.data) {
                    setErrorMessage((e.response.data as ApiError).message);
                } else {
                    setErrorMessage(e.message);
                }
            } else {
                console.error(e);
                setErrorMessage('An error occurred.');
            }
        }
    }
}
