export function objectToQueryString(object: Record<string, unknown>): string | undefined {
    if (typeof object !== 'object') {
        return undefined;
    }
    if (Object.keys(object).length < 1) {
        return undefined;
    }

    let query = '?';
    const keys = Object.keys(object);

    for (let i = 0; i < keys.length; i++) {
        const queryValue = `${object[keys[i]]}`.replaceAll(' ', '+');
        query += `${keys[i]}=${queryValue}`;
        if (i !== keys.length - 1) {
            query += '&';
        }
    }

    return query;
}
