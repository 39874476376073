import { Box, Link, Stack, Typography } from '@mui/material';
import { APP_STORE_URL } from '../config/app.constants';
import AppIconPng from '../resources/images/app_icon.png';
import DownloadOnAppStoreSvg from '../resources/svgs/download_on_app_store.svg';
import { fontSize } from '../util/preferences';

export default function SharedFromMistComponent() {
    return (
        <Stack
            direction="row"
            bgcolor={'primary.dark'}
            p={2}
            spacing={2}
            sx={{
                borderRadius: '12px',
            }}
        >
            <Box display={'flex'} alignItems={'center'}>
                <img
                    src={AppIconPng}
                    style={{ height: '42px', width: '42px', borderRadius: '8px' }}
                    alt=""
                />
            </Box>

            <Box flexGrow={1} display={'flex'} alignItems={'center'}>
                <Typography fontSize={fontSize.sub} color="text.secondary">
                    Shared from Mist
                </Typography>
            </Box>

            <Link href={APP_STORE_URL} display={'flex'} alignItems={'center'}>
                <img src={DownloadOnAppStoreSvg} alt="" />
            </Link>
        </Stack>
    );
}
