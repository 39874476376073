import axios from 'axios';
import { BACKEND_URL } from '../app/config/app.constants';
import { objectToQueryString } from '../app/util/object';
import GetPostsFilterDto from './dtos/get-posts-filter.dto';
import Post from './models/post.model';

export default class PostsService {
    PATH = 'posts';

    async getPosts(dto: GetPostsFilterDto): Promise<Post[]> {
        const queryString = objectToQueryString(dto);
        if (!queryString) {
            return [];
        }

        const url = `${BACKEND_URL}/${this.PATH}${queryString}`;

        const res = await axios.get(url);
        return res.data;
    }

    async getPostById(id: string): Promise<Post> {
        const url = `${BACKEND_URL}/${this.PATH}/${id}`;

        const res = await axios.get(url);
        return res.data;
    }
}
