/* eslint-disable no-useless-escape */
/* eslint-disable no-irregular-whitespace */
import { Box } from '@mui/material';

export default function TermsView() {
    const html = `
    <html>

<head>
<meta http-equiv=Content-Type content="text/html; charset=utf-8">
<meta name=Generator content="Microsoft Word 15 (filtered)">
<style>
<!--
 /* Font Definitions */
 @font-face
	{font-family:Courier;
	panose-1:2 7 4 9 2 2 5 2 4 4;}
@font-face
	{font-family:Wingdings;
	panose-1:5 0 0 0 0 0 0 0 0 0;}
@font-face
	{font-family:"MS Mincho";
	panose-1:2 2 6 9 4 2 5 8 3 4;}
@font-face
	{font-family:"MS Gothic";
	panose-1:2 11 6 9 7 2 5 8 2 4;}
@font-face
	{font-family:"Cambria Math";
	panose-1:2 4 5 3 5 4 6 3 2 4;}
@font-face
	{font-family:Cambria;
	panose-1:2 4 5 3 5 4 6 3 2 4;}
@font-face
	{font-family:"\@MS Mincho";
	panose-1:2 2 6 9 4 2 5 8 3 4;}
@font-face
	{font-family:"\@MS Gothic";
	panose-1:2 11 6 9 7 2 5 8 2 4;}
 /* Style Definitions */
 p.MsoNormal, li.MsoNormal, div.MsoNormal
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:10.0pt;
	margin-left:0in;
	line-height:115%;
	font-size:11.0pt;
	font-family:"Cambria",serif;}
a:link, span.MsoHyperlink
	{color:blue;
	text-decoration:underline;}
p.MsoListParagraph, li.MsoListParagraph, div.MsoListParagraph
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:10.0pt;
	margin-left:.5in;
	line-height:115%;
	font-size:11.0pt;
	font-family:"Cambria",serif;}
p.MsoListParagraphCxSpFirst, li.MsoListParagraphCxSpFirst, div.MsoListParagraphCxSpFirst
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:0in;
	margin-left:.5in;
	line-height:115%;
	font-size:11.0pt;
	font-family:"Cambria",serif;}
p.MsoListParagraphCxSpMiddle, li.MsoListParagraphCxSpMiddle, div.MsoListParagraphCxSpMiddle
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:0in;
	margin-left:.5in;
	line-height:115%;
	font-size:11.0pt;
	font-family:"Cambria",serif;}
p.MsoListParagraphCxSpLast, li.MsoListParagraphCxSpLast, div.MsoListParagraphCxSpLast
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:10.0pt;
	margin-left:.5in;
	line-height:115%;
	font-size:11.0pt;
	font-family:"Cambria",serif;}
span.1
	{mso-style-name:Гиперссылка1;
	color:blue;
	text-decoration:underline;}
.MsoChpDefault
	{font-family:"Cambria",serif;}
.MsoPapDefault
	{margin-bottom:10.0pt;
	line-height:115%;}
 /* Page Definitions */
 @page WordSection1
	{size:8.5in 11.0in;
	margin:1.0in 1.0in 1.0in 1.0in;}
div.WordSection1
	{page:WordSection1;}
 /* List Definitions */
 ol
	{margin-bottom:0in;}
ul
	{margin-bottom:0in;}
-->
</style>

</head>

<body lang=EN-US link=blue vlink=purple style='word-wrap:break-word'>

<div class=WordSection1>

<p class=MsoNormal align=center style='margin-bottom:0in;margin-top:0in;
text-align:center;line-height:normal'><b><span style='font-family:"Arial",sans-serif;
color:black'>TERMS OF SERVICE</span></b></p>

<p class=MsoNormal align=center style='margin-bottom:0in;margin-bottom:0in;
margin-top:0in;text-align:center;line-height:normal'><b><span lang=UK
style='font-family:"Arial",sans-serif;color:black'> </span></b></p>

<p class=MsoNormal style='margin-bottom:0in;margin-bottom:0in;margin-top:0in;
text-align:justify;text-justify:inter-ideograph;line-height:normal'><span
style='font-family:"Arial",sans-serif;color:black'>Last
updated:&nbsp;11/13/2022</span></p>

<p class=MsoNormal style='margin-bottom:0in;margin-bottom:10.0pt;text-align:
justify;text-justify:inter-ideograph;line-height:normal'><span
style='font-family:"Arial",sans-serif'> </span></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:0in;margin-bottom:
0in;margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-17.85pt;line-height:normal;page-break-after:avoid'><b><span
style='font-family:"Arial",sans-serif'>1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span></b><b><u><span style='font-family:"Arial",sans-serif'>Introduction</span></u></b></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;line-height:
normal'><span style='font-family:"Arial",sans-serif;color:black'> </span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;line-height:
normal'><span style='font-family:"Arial",sans-serif;color:black'>These Terms of
Service (“<b>Terms</b>”, “<b>Terms of Service</b>”) govern your use of&nbsp;our
mobile application&nbsp;Mist</span><span style='font-family:"Arial",sans-serif'>&nbsp;operated
by&nbsp;Yoan Dubuc.</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;line-height:
normal'><span style='font-family:"Arial",sans-serif;color:black'> </span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;line-height:
normal'><span style='font-family:"Arial",sans-serif;color:black'>Our Privacy
Policy also governs your use of our Service and explains how we collect,
safeguard and disclose information that results from your use of our web pages.
Please read it here https://web.mist.greenknightlabs.com/privacy.</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;line-height:
normal'><span style='font-family:"Arial",sans-serif;color:black'> </span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;line-height:
normal'><span style='font-family:"Arial",sans-serif;color:black'>Your agreement
with us includes these Terms&nbsp;and our Privacy Policy&nbsp;(“<b>Agreements</b>”).
You acknowledge that you have read and understood Agreements, and agree to be
bound of them.</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;line-height:
normal'><span style='font-family:"Arial",sans-serif;color:black'> </span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;line-height:
normal'><span style='font-family:"Arial",sans-serif;color:black'>If you do not
agree with (or cannot comply with) Agreements, then you may not use the
Service, but please let us know by emailing at support@mist.greenknightlabs.com
so we can try to find a solution. These Terms apply to all visitors, users and
others who wish to access or use Service.</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;line-height:
normal'><span style='font-family:"Arial",sans-serif;color:black'> </span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;line-height:
normal'><span style='font-family:"Arial",sans-serif;color:black'>Thank you for
being responsible.</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;line-height:
normal'><span style='font-family:"Arial",sans-serif;color:black'> </span></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:0in;margin-bottom:
0in;margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-17.85pt;line-height:normal;page-break-after:avoid'><b><span
style='font-family:"Arial",sans-serif'>2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span></b><b><u><span style='font-family:"Arial",sans-serif'>Communications</span></u></b></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;line-height:
normal'><span style='font-family:"Arial",sans-serif;color:black'>By creating an
Account on our Service, you agree to subscribe to newsletters, marketing or
promotional materials and other information we may send. However, you may opt
out of receiving any, or all, of these communications from us by following the
unsubscribe link or by emailing at.</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;line-height:
normal'><span style='font-family:"Arial",sans-serif;color:black'> </span></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:0in;margin-bottom:
0in;margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-17.85pt;line-height:normal;page-break-after:avoid'><b><span
style='font-family:"Arial",sans-serif'>3.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span></b><b><u><span style='font-family:"Arial",sans-serif'>Purchases</span></u></b></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;line-height:
normal'><span style='font-family:"Arial",sans-serif;color:black'>If you wish to
purchase any product or service made available through Service (“<b>Purchase</b>”),
you may be asked to supply certain information relevant to your Purchase
including, without limitation, your credit card number, the expiration date of your
credit card, your billing address, and your shipping information.</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;line-height:
normal'><span style='font-family:"Arial",sans-serif;color:black'> </span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;line-height:
normal'><span style='font-family:"Arial",sans-serif;color:black'>You represent
and warrant that: (i) you have the legal right to use any credit card(s) or
other payment method(s) in connection with any Purchase; and that (ii) the
information you supply to us is true, correct and complete.</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;line-height:
normal'><span style='font-family:"Arial",sans-serif;color:black'> </span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;line-height:
normal'><span style='font-family:"Arial",sans-serif;color:black'>We may employ
the use of third party services for the purpose of facilitating payment and the
completion of Purchases. By submitting your information, you grant us the right
to provide the information to these third parties&nbsp;subject to our Privacy
Policy.</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;line-height:
normal'><span style='font-family:"Arial",sans-serif;color:black'> </span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;line-height:
normal'><span style='font-family:"Arial",sans-serif;color:black'>We reserve the
right to refuse or cancel your order at any time for reasons including but not
limited to: product or service availability, errors in the description or price
of the product or service, error in your order or other reasons.</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;line-height:
normal'><span style='font-family:"Arial",sans-serif;color:black'> </span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;line-height:
normal'><span style='font-family:"Arial",sans-serif;color:black'>We reserve the
right to refuse or cancel your order if fraud or an unauthorized or illegal
transaction is suspected. </span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;line-height:
normal'><span style='font-family:"Arial",sans-serif;color:black'> </span></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:0in;margin-bottom:
0in;margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-17.85pt;line-height:normal;page-break-after:avoid'><b><span
style='font-family:"Arial",sans-serif'>4.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span></b><b><u><span style='font-family:"Arial",sans-serif'>Subscriptions</span></u></b></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;line-height:
normal'><span style='font-family:"Arial",sans-serif;color:black'>Some parts of
Service are billed on a subscription basis (“<b>Subscription(s)</b>”). You will
be billed in advance on a recurring and periodic basis (“<b>Billing Cycle</b>”).
Billing cycles are set&nbsp;either on a monthly or annual basis, depending on
the type of subscription plan you select when purchasing a Subscription.</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;line-height:
normal'><span style='font-family:"Arial",sans-serif;color:black'> </span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;line-height:
normal'><span style='font-family:"Arial",sans-serif;color:black'>At the end of
each Billing Cycle, your Subscription will automatically renew under the exact
same conditions unless you cancel it or&nbsp;Yoan Dubuc&nbsp;cancels it. You
may cancel your Subscription renewal either through your online account
management page or by contacting&nbsp;Yoan Dubuc&nbsp;customer support team.</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;line-height:
normal'><span style='font-family:"Arial",sans-serif;color:black'> </span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;line-height:
normal'><span style='font-family:"Arial",sans-serif;color:black'>A valid
payment method, including&nbsp;credit card or PayPal, is required to process
the payment for your subscription. You shall provide&nbsp;Yoan Dubuc&nbsp;with
accurate and complete billing information including full name, address, state,
zip code, telephone number, and a valid payment method information. By
submitting such payment information, you automatically authorize&nbsp;Yoan
Dubuc&nbsp;to charge all Subscription fees incurred through your account to any
such payment instruments.</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;line-height:
normal'><span style='font-family:"Arial",sans-serif;color:black'> </span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;line-height:
normal'><span style='font-family:"Arial",sans-serif;color:black'>Should
automatic billing fail to occur for any reason,&nbsp;Yoan Dubuc&nbsp;will issue
an electronic invoice indicating that you must proceed manually, within a
certain deadline date, with the full payment corresponding to the billing
period as indicated on the invoice.</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;line-height:
normal'><span style='font-family:"Arial",sans-serif;color:black'> </span></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:0in;margin-bottom:
0in;margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-17.85pt;line-height:normal;page-break-after:avoid'><b><span
style='font-family:"Arial",sans-serif'>5.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span></b><b><u><span style='font-family:"Arial",sans-serif'>Free Trial</span></u></b></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;line-height:
normal'><span style='font-family:"Arial",sans-serif'>Yoan Dubuc&nbsp;may, at
its sole discretion, offer a Subscription with a free trial for a limited
period of time (“<b><span style='color:black'>Free Trial</span></b><span
style='color:black'>”).</span></span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;line-height:
normal'><span style='font-family:"Arial",sans-serif;color:black'> </span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;line-height:
normal'><span style='font-family:"Arial",sans-serif;color:black'>You may be
required to enter your billing information in order to sign up for Free Trial.</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;line-height:
normal'><span style='font-family:"Arial",sans-serif;color:black'> </span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;line-height:
normal'><span style='font-family:"Arial",sans-serif;color:black'>If you do
enter your billing information when signing up for Free Trial, you will not be
charged by&nbsp;Yoan Dubuc&nbsp;until Free Trial has expired. On the last day
of Free Trial period, unless you cancelled your Subscription, you will be
automatically charged the applicable Subscription fees for the type of
Subscription you have selected.</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;line-height:
normal'><span style='font-family:"Arial",sans-serif;color:black'> </span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;line-height:
normal'><span style='font-family:"Arial",sans-serif;color:black'>At any time
and without notice,&nbsp;Yoan Dubuc&nbsp;reserves the right to (i) modify Terms
of Service of Free Trial offer, or (ii) cancel such Free Trial offer.</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;line-height:
normal'><span style='font-family:"Arial",sans-serif;color:black'> </span></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:0in;margin-bottom:
0in;margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-17.85pt;line-height:normal;page-break-after:avoid'><b><span
style='font-family:"Arial",sans-serif'>6.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span></b><b><u><span style='font-family:"Arial",sans-serif'>Fee
Changes</span></u></b></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;line-height:
normal'><span style='font-family:"Arial",sans-serif'>Yoan Dubuc, in its sole
discretion and at any time, may modify Subscription fees for the Subscriptions.
Any Subscription fee change will become effective at the end of the
then-current Billing Cycle.</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;line-height:
normal'><span style='font-family:"Arial",sans-serif;color:black'> </span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;line-height:
normal'><span style='font-family:"Arial",sans-serif'>Yoan Dubuc&nbsp;will
provide you with a reasonable prior notice of any change in Subscription fees
to give you an opportunity to terminate your Subscription before such change
becomes effective.</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;line-height:
normal'><span style='font-family:"Arial",sans-serif;color:black'> </span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;line-height:
normal'><span style='font-family:"Arial",sans-serif;color:black'>Your continued
use of Service after Subscription fee change comes into effect constitutes your
agreement to pay the modified Subscription fee amount.</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;line-height:
normal'><span style='font-family:"Arial",sans-serif;color:black'> </span></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:0in;margin-bottom:
0in;margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-17.85pt;line-height:normal;page-break-after:avoid'><b><span
style='font-family:"Arial",sans-serif'>7.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span></b><b><u><span style='font-family:"Arial",sans-serif'>Refunds</span></u></b></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;line-height:
normal'><span style='font-family:"Arial",sans-serif;color:black'>Except when
required by law, paid Subscription fees are non-refundable.</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;line-height:
normal'><span style='font-family:"Arial",sans-serif;color:black'> </span></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:0in;margin-bottom:
0in;margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-17.85pt;line-height:normal;page-break-after:avoid'><b><span
style='font-family:"Arial",sans-serif'>8.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span></b><b><u><span style='font-family:"Arial",sans-serif'>Content</span></u></b></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;line-height:
normal'><span style='font-family:"Arial",sans-serif;color:black'>Our Service
allows you to post, link, store, share and otherwise make available certain information,
text, graphics, videos, or other material (“<b>Content</b>”). You are
responsible for Content that you post on or through Service, including its
legality, reliability, and appropriateness.</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;line-height:
normal'><span style='font-family:"Arial",sans-serif;color:black'> </span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;line-height:
normal'><span style='font-family:"Arial",sans-serif;color:black'>By posting
Content on or through Service, You represent and warrant that: (i) Content is
yours (you own it) and/or you have the right to use it and the right to grant
us the rights and license as provided in these Terms, and (ii) that the posting
of your Content on or through Service does not violate the privacy rights,
publicity rights, copyrights, contract rights or any other rights of any person
or entity. We reserve the right to terminate the account of anyone found to be
infringing on a copyright.</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;line-height:
normal'><span style='font-family:"Arial",sans-serif;color:black'> </span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;line-height:
normal'><span style='font-family:"Arial",sans-serif;color:black'>You retain any
and all of your rights to any Content you submit, post or display on or through
Service and you are responsible for protecting those rights. We take no
responsibility and assume no liability for Content you or any third party posts
on or through Service. However, by posting Content using Service you grant us
the right and license to use, modify, publicly perform, publicly display,
reproduce, and distribute such Content on and through Service. You agree that
this license includes the right for us to make your Content available to other
users of Service, who may also use your Content subject to these Terms.</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;line-height:
normal'><span style='font-family:"Arial",sans-serif;color:black'> </span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;line-height:
normal'><span style='font-family:"Arial",sans-serif'>Yoan Dubuc&nbsp;has the
right but not the obligation to monitor and edit all Content provided by users.</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;line-height:
normal'><span style='font-family:"Arial",sans-serif;color:black'> </span></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:0in;margin-bottom:
0in;margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-17.85pt;line-height:normal;page-break-after:avoid'><b><span
style='font-family:"Arial",sans-serif'>9.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span></b><b><u><span style='font-family:"Arial",sans-serif'>Prohibited
Uses</span></u></b></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;line-height:
normal;page-break-after:avoid;background:white'><span style='font-family:"Arial",sans-serif;
color:black'>You may use Service only for lawful purposes and in accordance
with Terms. You agree not to use Service:</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;line-height:
normal;page-break-after:avoid;background:white'><span style='font-family:"Arial",sans-serif;
color:black'> </span></p>

<p class=MsoListParagraphCxSpFirst style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:35.7pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-17.85pt;line-height:normal'><span style='font-family:"Arial",sans-serif'>(a)<span
style='font:7.0pt "Times New Roman"'>&nbsp; </span></span><span
style='font-family:"Arial",sans-serif'>In any way that violates any applicable
national or international law or regulation.</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:35.7pt;text-align:justify;text-justify:inter-ideograph;
line-height:normal'><span style='font-family:"Arial",sans-serif'> </span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:35.7pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-17.85pt;line-height:normal'><span style='font-family:"Arial",sans-serif'>(b)<span
style='font:7.0pt "Times New Roman"'>&nbsp; </span></span><span
style='font-family:"Arial",sans-serif'>For the purpose of exploiting, harming,
or attempting to exploit or harm minors in any way by exposing them to
inappropriate content or otherwise.</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:35.7pt;text-align:justify;text-justify:inter-ideograph;
line-height:normal'><span style='font-family:"Arial",sans-serif'> </span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:35.7pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-17.85pt;line-height:normal'><span style='font-family:"Arial",sans-serif'>(c)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span></span><span
style='font-family:"Arial",sans-serif'>To transmit, or procure the sending of,
any advertising or promotional material, including any “junk mail”, “chain
letter,” “spam,” or any other similar solicitation.</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:35.7pt;text-align:justify;text-justify:inter-ideograph;
line-height:normal'><span style='font-family:"Arial",sans-serif'> </span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:35.7pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-17.85pt;line-height:normal'><span style='font-family:"Arial",sans-serif'>(d)<span
style='font:7.0pt "Times New Roman"'>&nbsp; </span></span><span
style='font-family:"Arial",sans-serif'>To impersonate or attempt to impersonate
Company, a Company employee, another user, or any other person or entity.</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:35.7pt;text-align:justify;text-justify:inter-ideograph;
line-height:normal'><span style='font-family:"Arial",sans-serif'> </span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:35.7pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-17.85pt;line-height:normal'><span style='font-family:"Arial",sans-serif'>(e)<span
style='font:7.0pt "Times New Roman"'>&nbsp; </span></span><span
style='font-family:"Arial",sans-serif'>In any way that infringes upon the
rights of others, or in any way is illegal, threatening, fraudulent, or
harmful, or in connection with any unlawful, illegal, fraudulent, or harmful
purpose or activity.</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:35.7pt;text-align:justify;text-justify:inter-ideograph;
line-height:normal'><span style='font-family:"Arial",sans-serif'> </span></p>

<p class=MsoListParagraphCxSpLast style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:35.7pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-17.85pt;line-height:normal'><span style='font-family:"Arial",sans-serif'>(f)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><span
style='font-family:"Arial",sans-serif'>To engage in any other conduct that
restricts or inhibits anyone’s use or enjoyment of Service, or which, as
determined by us, may harm or offend Company or users of Service or expose them
to liability.</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;line-height:
normal'><span style='font-family:"Arial",sans-serif'> </span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;line-height:
normal;page-break-after:avoid;background:white'><span style='font-family:"Arial",sans-serif;
color:black'>Additionally, you agree not to:</span></p>

<p class=MsoNormal style='margin-bottom:0in;text-align:justify;text-justify:
inter-ideograph;line-height:normal;page-break-after:avoid;background:white'><span
style='font-family:"Arial",sans-serif;color:black'> </span></p>

<p class=MsoListParagraphCxSpFirst style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:35.7pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-17.85pt;line-height:normal'><span style='font-family:"Arial",sans-serif'>(a)<span
style='font:7.0pt "Times New Roman"'>&nbsp; </span></span><span
style='font-family:"Arial",sans-serif'>Use Service in any manner that could
disable, overburden, damage, or impair Service or interfere with any other
party’s use of Service, including their ability to engage in real time
activities through Service.</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:35.7pt;text-align:justify;text-justify:inter-ideograph;
line-height:normal'><span style='font-family:"Arial",sans-serif'> </span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:35.7pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-17.85pt;line-height:normal'><span style='font-family:"Arial",sans-serif'>(b)<span
style='font:7.0pt "Times New Roman"'>&nbsp; </span></span><span
style='font-family:"Arial",sans-serif'>Use any robot, spider, or other
automatic device, process, or means to access Service for any purpose,
including monitoring or copying any of the material on Service.</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:35.7pt;text-align:justify;text-justify:inter-ideograph;
line-height:normal'><span style='font-family:"Arial",sans-serif'> </span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:35.7pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-17.85pt;line-height:normal'><span style='font-family:"Arial",sans-serif'>(c)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span></span><span
style='font-family:"Arial",sans-serif'>Use any manual process to monitor or
copy any of the material on Service or for any other unauthorized purpose
without our prior written consent.</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:35.7pt;text-align:justify;text-justify:inter-ideograph;
line-height:normal'><span style='font-family:"Arial",sans-serif'> </span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:35.7pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-17.85pt;line-height:normal'><span style='font-family:"Arial",sans-serif'>(d)<span
style='font:7.0pt "Times New Roman"'>&nbsp; </span></span><span
style='font-family:"Arial",sans-serif'>Use any device, software, or routine
that interferes with the proper working of Service.</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:35.7pt;text-align:justify;text-justify:inter-ideograph;
line-height:normal'><span style='font-family:"Arial",sans-serif'> </span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:35.7pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-17.85pt;line-height:normal'><span style='font-family:"Arial",sans-serif'>(e)<span
style='font:7.0pt "Times New Roman"'>&nbsp; </span></span><span
style='font-family:"Arial",sans-serif'>Introduce any viruses, trojan horses,
worms, logic bombs, or other material which is malicious or technologically
harmful.</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:35.7pt;text-align:justify;text-justify:inter-ideograph;
line-height:normal'><span style='font-family:"Arial",sans-serif'> </span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:35.7pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-17.85pt;line-height:normal'><span style='font-family:"Arial",sans-serif'>(f)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><span
style='font-family:"Arial",sans-serif'>Attempt to gain unauthorized access to,
interfere with, damage, or disrupt any parts of Service, the server on which
Service is stored, or any server, computer, or database connected to Service.</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:35.7pt;text-align:justify;text-justify:inter-ideograph;
line-height:normal'><span style='font-family:"Arial",sans-serif'> </span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:35.7pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-17.85pt;line-height:normal'><span style='font-family:"Arial",sans-serif'>(g)<span
style='font:7.0pt "Times New Roman"'>&nbsp; </span></span><span
style='font-family:"Arial",sans-serif'>Attack Service via a denial-of-service
attack or a distributed denial-of-service attack.</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:35.7pt;text-align:justify;text-justify:inter-ideograph;
line-height:normal'><span style='font-family:"Arial",sans-serif'> </span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:35.7pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-17.85pt;line-height:normal'><span style='font-family:"Arial",sans-serif'>(h)<span
style='font:7.0pt "Times New Roman"'>&nbsp; </span></span><span
style='font-family:"Arial",sans-serif'>Take any action that may damage or
falsify Company rating.</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:35.7pt;text-align:justify;text-justify:inter-ideograph;
line-height:normal'><span style='font-family:"Arial",sans-serif'> </span></p>

<p class=MsoListParagraphCxSpLast style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:35.7pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-17.85pt;line-height:normal'><span style='font-family:"Arial",sans-serif'>(i)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><span
style='font-family:"Arial",sans-serif'>Otherwise attempt to interfere with the
proper working of Service.</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal'><span
style='font-family:"Arial",sans-serif'> </span></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:0in;margin-bottom:
0in;margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-17.85pt;line-height:normal;page-break-after:avoid'><b><span
style='font-family:"Arial",sans-serif'>10.<span style='font:7.0pt "Times New Roman"'>&nbsp;
</span></span></b><b><u><span style='font-family:"Arial",sans-serif'>Analytics</span></u></b></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;line-height:
normal;page-break-after:avoid'><b><u><span style='font-family:"Arial",sans-serif'> </span></u></b></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;line-height:
normal;page-break-after:avoid'><span style='font-family:"Arial",sans-serif'>We
may use third-party Service Providers to monitor and analyze the use of our
Service.</span></p>

<p class=MsoNormal style='margin-bottom:0in;text-align:justify;text-justify:
inter-ideograph;line-height:normal;page-break-after:avoid'><span
style='font-family:"Arial",sans-serif'> </span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;line-height:
normal;page-break-after:avoid'><b><span style='font-family:"Arial",sans-serif'>Google
Analytics</span></b></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;line-height:
normal'><span style='font-family:"Arial",sans-serif'>Google Analytics is a web
analytics service offered by Google that tracks and reports website traffic.
Google uses the data collected to track and monitor the use of our Service.
This data is shared with other Google services. Google may use the collected data
to contextualise and personalise the ads of its own advertising network.</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;line-height:
normal'><span style='font-family:"Arial",sans-serif'> </span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;line-height:
normal'><span style='font-family:"Arial",sans-serif'>For more information on
the privacy practices of Google, please visit the Google Privacy Terms web
page: </span><a href="https://policies.google.com/privacy?hl=en"><span
style='font-family:"Arial",sans-serif'>https://policies.google.com/privacy?hl=en</span></a></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;line-height:
normal'><u><span style='font-family:"Arial",sans-serif;color:blue'> </span></u></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;line-height:
normal'><span style='font-family:"Arial",sans-serif'>We also encourage you to
review the Google's policy for safeguarding your data: </span><a
href="https://support.google.com/analytics/answer/6004245"><span
style='font-family:"Arial",sans-serif'>https://support.google.com/analytics/answer/6004245</span></a><span
style='font-family:"Arial",sans-serif'>.</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;line-height:
normal'><span style='font-family:"Arial",sans-serif'> </span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;line-height:
normal;page-break-after:avoid'><b><span style='font-family:"Arial",sans-serif'>Firebase</span></b></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;line-height:
normal'><span style='font-family:"Arial",sans-serif'>Firebase is analytics
service provided by Google Inc.</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;line-height:
normal'><span style='font-family:"Arial",sans-serif'> </span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;line-height:
normal'><span style='font-family:"Arial",sans-serif'>You may opt-out of certain
Firebase features through your mobile device settings, such as your device
advertising settings or by following the instructions provided by Google in
their Privacy Policy: </span><a href="https://policies.google.com/privacy?hl=en"><span
style='font-family:"Arial",sans-serif'>https://policies.google.com/privacy?hl=en</span></a></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;line-height:
normal'><u><span style='font-family:"Arial",sans-serif;color:blue'> </span></u></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;line-height:
normal'><span style='font-family:"Arial",sans-serif'>For more information on
what type of information Firebase collects, please visit the Google Privacy
Terms web page: </span><a href="https://policies.google.com/privacy?hl=en"><span
style='font-family:"Arial",sans-serif'>https://policies.google.com/privacy?hl=en</span></a></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;line-height:
normal'><u><span style='font-family:"Arial",sans-serif;color:blue'> </span></u></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;line-height:
normal'><span style='font-family:"Arial",sans-serif;color:black'> </span></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:0in;margin-bottom:
0in;margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-17.85pt;line-height:normal;page-break-after:avoid'><b><span
style='font-family:"Arial",sans-serif'>12.<span style='font:7.0pt "Times New Roman"'>&nbsp;
</span></span></b><b><u><span style='font-family:"Arial",sans-serif'>Accounts</span></u></b></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;line-height:
normal'><span style='font-family:"Arial",sans-serif;color:black'>When you
create an account with us, you guarantee that you are above the age of 13, and
that the information you provide us is accurate, complete, and current at all
times. Inaccurate, incomplete, or obsolete information may result in the
immediate termination of your account on Service.</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;line-height:
normal'><span style='font-family:"Arial",sans-serif;color:black'> </span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;line-height:
normal'><span style='font-family:"Arial",sans-serif;color:black'>You are
responsible for maintaining the confidentiality of your account and password,
including but not limited to the restriction of access to your computer and/or
account. You agree to accept responsibility for any and all activities or
actions that occur under your account and/or password, whether your password is
with our Service or a third-party service. You must notify us immediately upon
becoming aware of any breach of security or unauthorized use of your account.</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;line-height:
normal'><span style='font-family:"Arial",sans-serif;color:black'> </span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;line-height:
normal'><span style='font-family:"Arial",sans-serif;color:black'>You may not
use as a username the name of another person or entity or that is not lawfully
available for use, a name or trademark that is subject to any rights of another
person or entity other than you, without appropriate authorization. You may not
use as a username any name that is offensive, vulgar or obscene.</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;line-height:
normal'><span style='font-family:"Arial",sans-serif;color:black'> </span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;line-height:
normal'><span style='font-family:"Arial",sans-serif;color:black'>We reserve the
right to refuse service, terminate accounts, remove or edit content, or cancel
orders in our sole discretion.</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;line-height:
normal'><span style='font-family:"Arial",sans-serif;color:black'> </span></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:0in;margin-bottom:
0in;margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-17.85pt;line-height:normal;page-break-after:avoid'><b><span
style='font-family:"Arial",sans-serif'>13.<span style='font:7.0pt "Times New Roman"'>&nbsp;
</span></span></b><b><u><span style='font-family:"Arial",sans-serif'>Intellectual
Property</span></u></b></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;line-height:
normal'><span style='font-family:"Arial",sans-serif;color:black'>Service and
its original content (excluding Content provided by users), features and
functionality are and will remain the exclusive property of&nbsp;Yoan
Dubuc&nbsp;and its licensors. Service is protected by copyright, trademark, and
other laws of&nbsp;the United States. Our trademarks and trade dress may not be
used in connection with any product or service without the prior written
consent of&nbsp;Yoan Dubuc.</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;line-height:
normal'><span style='font-family:"Arial",sans-serif;color:black'> </span></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:0in;margin-bottom:
0in;margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-17.85pt;line-height:normal;page-break-after:avoid'><b><span
style='font-family:"Arial",sans-serif'>14.<span style='font:7.0pt "Times New Roman"'>&nbsp;
</span></span></b><b><u><span style='font-family:"Arial",sans-serif'>Copyright
Policy</span></u></b></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;line-height:
normal'><span style='font-family:"Arial",sans-serif;color:black'>We respect the
intellectual property rights of others. It is our policy to respond to any
claim that Content posted on Service infringes on the copyright or other
intellectual property rights (“<b>Infringement</b>”) of any person or entity.</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;line-height:
normal'><span style='font-family:"Arial",sans-serif;color:black'> </span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;line-height:
normal'><span style='font-family:"Arial",sans-serif;color:black'>If you are a
copyright owner, or authorized on behalf of one, and you believe that the
copyrighted work has been copied in a way that constitutes copyright
infringement, please submit your claim via email
to&nbsp;support@mist.greenknightlabs.com, with the subject line: “Copyright
Infringement” and include in your claim a detailed description of the alleged
Infringement as detailed below, under “DMCA Notice and Procedure for Copyright
Infringement Claims”</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;line-height:
normal'><span style='font-family:"Arial",sans-serif;color:black'> </span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;line-height:
normal'><span style='font-family:"Arial",sans-serif;color:black'>You may be
held accountable for damages (including costs and attorneys' fees) for
misrepresentation or bad-faith claims on the infringement of any Content found
on and/or through Service on your copyright.</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;line-height:
normal'><span style='font-family:"Arial",sans-serif;color:black'> </span></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:0in;margin-bottom:
0in;margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-17.85pt;line-height:normal;page-break-after:avoid'><b><span
style='font-family:"Arial",sans-serif'>15.<span style='font:7.0pt "Times New Roman"'>&nbsp;
</span></span></b><b><u><span style='font-family:"Arial",sans-serif'>DMCA
Notice and Procedure for Copyright Infringement Claims</span></u></b></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;line-height:
normal;page-break-after:avoid'><span style='font-family:"Arial",sans-serif;
color:black'>You may submit a notification pursuant to the Digital Millennium
Copyright Act (DMCA) by providing our Copyright Agent with the following
information in writing (see 17 U.S.C 512(c)(3) for further detail):</span></p>

<p class=MsoNormal style='margin-bottom:0in;text-align:justify;text-justify:
inter-ideograph;line-height:normal;page-break-after:avoid'><span
style='font-family:"Arial",sans-serif;color:black'> </span></p>

<p class=MsoListParagraphCxSpFirst style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:35.7pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-17.85pt;line-height:normal'><span style='font-family:"Arial",sans-serif'>(a)<span
style='font:7.0pt "Times New Roman"'>&nbsp; </span></span><span
style='font-family:"Arial",sans-serif'>an electronic or physical signature of
the person authorized to act on behalf of the owner of the copyright's
interest;</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:35.7pt;text-align:justify;text-justify:inter-ideograph;
line-height:normal'><span style='font-family:"Arial",sans-serif'> </span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:35.7pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-17.85pt;line-height:normal'><span style='font-family:"Arial",sans-serif'>(b)<span
style='font:7.0pt "Times New Roman"'>&nbsp; </span></span><span
style='font-family:"Arial",sans-serif'>a description of the copyrighted work
that you claim has been infringed, including the URL (i.e., web page address)
of the location where the copyrighted work exists or a copy of the copyrighted
work;</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:35.7pt;text-align:justify;text-justify:inter-ideograph;
line-height:normal'><span style='font-family:"Arial",sans-serif'> </span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:35.7pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-17.85pt;line-height:normal'><span style='font-family:"Arial",sans-serif'>(c)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span></span><span
style='font-family:"Arial",sans-serif'>identification of the URL or other
specific location on Service where the material that you claim is infringing is
located;</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:35.7pt;text-align:justify;text-justify:inter-ideograph;
line-height:normal'><span style='font-family:"Arial",sans-serif'> </span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:35.7pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-17.85pt;line-height:normal'><span style='font-family:"Arial",sans-serif'>(d)<span
style='font:7.0pt "Times New Roman"'>&nbsp; </span></span><span
style='font-family:"Arial",sans-serif'>your address, telephone number, and
email address;</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:35.7pt;text-align:justify;text-justify:inter-ideograph;
line-height:normal'><span style='font-family:"Arial",sans-serif'> </span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:35.7pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-17.85pt;line-height:normal'><span style='font-family:"Arial",sans-serif'>(e)<span
style='font:7.0pt "Times New Roman"'>&nbsp; </span></span><span
style='font-family:"Arial",sans-serif'>a statement by you that you have a good
faith belief that the disputed use is not authorized by the copyright owner,
its agent, or the law;</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:35.7pt;text-align:justify;text-justify:inter-ideograph;
line-height:normal'><span style='font-family:"Arial",sans-serif'> </span></p>

<p class=MsoListParagraphCxSpLast style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:35.7pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-17.85pt;line-height:normal'><span style='font-family:"Arial",sans-serif'>(f)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><span
style='font-family:"Arial",sans-serif'>a statement by you, made under penalty
of perjury, that the above information in your notice is accurate and that you
are the copyright owner or authorized to act on the copyright owner's behalf.</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;line-height:
normal'><span style='font-family:"Arial",sans-serif'> </span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;line-height:
normal'><span style='font-family:"Arial",sans-serif;color:black'>You can
contact our Copyright Agent via email at&nbsp;support@mist.greenknightlabs.com</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;line-height:
normal'><span style='font-family:"Arial",sans-serif;color:black'> </span></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:0in;margin-bottom:
0in;margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-17.85pt;line-height:normal;page-break-after:avoid'><b><span
style='font-family:"Arial",sans-serif'>16.<span style='font:7.0pt "Times New Roman"'>&nbsp;
</span></span></b><b><u><span style='font-family:"Arial",sans-serif'>Error
Reporting and Feedback</span></u></b></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;line-height:
normal'><span style='font-family:"Arial",sans-serif;color:black'>You may
provide us&nbsp;either directly at feedback@mist.greenknightlabs.com or via
third party sites and tools&nbsp;with information and feedback concerning
errors, suggestions for improvements, ideas, problems, complaints, and other
matters related to our Service (“<b>Feedback</b>”). You acknowledge and agree
that: (i) you shall not retain, acquire or assert any intellectual property
right or other right, title or interest in or to the Feedback; (ii) Company may
have development ideas similar to the Feedback; (iii) Feedback does not contain
confidential information or proprietary information from you or any third
party; and (iv) Company is not under any obligation of confidentiality with
respect to the Feedback. In the event the transfer of the ownership to the
Feedback is not possible due to applicable mandatory laws, you grant Company
and its affiliates an exclusive, transferable, irrevocable, free-of-charge,
sub-licensable, unlimited and perpetual right to use (including copy, modify,
create derivative works, publish, distribute and commercialize) Feedback in any
manner and for any purpose.</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;line-height:
normal'><span style='font-family:"Arial",sans-serif;color:black'> </span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;line-height:
normal'><span style='font-family:"Arial",sans-serif;color:black'>The third
party sites and tools mentioned above include the following:</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;line-height:
normal'><span style='font-family:"Arial",sans-serif;color:black'> </span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;line-height:
normal;page-break-after:avoid'><b><span style='font-family:"Arial",sans-serif'>Firebase
Crashlytics</span></b></p>

<p class=MsoListParagraphCxSpFirst style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;
line-height:normal'><span style='font-family:"Arial",sans-serif;color:black'>Firebase
Crashlytics is </span><span style='font-family:"Arial",sans-serif'>bug
reporting service provided by Google Inc.</span></p>

<p class=MsoListParagraphCxSpLast style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;
line-height:normal'><span style='font-family:"Arial",sans-serif'> </span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;line-height:
normal'><span style='font-family:"Arial",sans-serif'>You may opt-out of certain
Firebase features through your mobile device settings, such as your device
advertising settings or by following the instructions provided by Google in
their Privacy Policy: </span><a href="https://policies.google.com/privacy?hl=en"><span
style='font-family:"Arial",sans-serif'>https://policies.google.com/privacy?hl=en</span></a></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;line-height:
normal'><span class=MsoHyperlink><span style='font-family:"Arial",sans-serif'> </span></span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;line-height:
normal'><span style='font-family:"Arial",sans-serif'>For more information on
what type of information Firebase collects, please visit the Google Privacy
Terms web page: </span><a href="https://policies.google.com/privacy?hl=en"><span
style='font-family:"Arial",sans-serif'>https://policies.google.com/privacy?hl=en</span></a></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph'><span
class=MsoHyperlink><span style='font-family:"Arial",sans-serif'> </span></span></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:0in;margin-bottom:
0in;margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-17.85pt;line-height:normal;page-break-after:avoid'><b><span
style='font-family:"Arial",sans-serif'>17.<span style='font:7.0pt "Times New Roman"'>&nbsp;
</span></span></b><b><u><span style='font-family:"Arial",sans-serif'>Links To
Other Web Sites</span></u></b></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;line-height:
normal'><span style='font-family:"Arial",sans-serif;color:black'>Our Service
may contain links to third party web sites or services that are not owned or
controlled by&nbsp;Yoan Dubuc.</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;line-height:
normal'><span style='font-family:"Arial",sans-serif'> </span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;line-height:
normal'><span style='font-family:"Arial",sans-serif'>Yoan Dubuc&nbsp;has no
control over, and assumes no responsibility for the content, privacy policies,
or practices of any third party web sites or services. We do not warrant the
offerings of any of these entities/individuals or their websites.</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;line-height:
normal'><span style='font-family:"Arial",sans-serif;color:black'> </span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;line-height:
normal'><span style='font-family:"Arial",sans-serif;color:black'>YOU
ACKNOWLEDGE AND AGREE THAT&nbsp;</span><span style='font-family:"Arial",sans-serif;
text-transform:uppercase'>Yoan Dubuc</span><span style='font-family:"Arial",sans-serif'>&nbsp;<span
style='color:black'>SHALL NOT BE RESPONSIBLE OR LIABLE, DIRECTLY OR INDIRECTLY,
FOR ANY DAMAGE OR LOSS CAUSED OR ALLEGED TO BE CAUSED BY OR IN CONNECTION WITH
USE OF OR RELIANCE ON ANY SUCH CONTENT, GOODS OR SERVICES AVAILABLE ON OR
THROUGH ANY SUCH THIRD PARTY WEB SITES OR SERVICES.</span></span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;line-height:
normal'><span style='font-family:"Arial",sans-serif;color:black'> </span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;line-height:
normal'><span style='font-family:"Arial",sans-serif;color:black'>WE STRONGLY
ADVISE YOU TO READ THE TERMS OF SERVICE AND PRIVACY POLICIES OF ANY THIRD PARTY
WEB SITES OR SERVICES THAT YOU VISIT.</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;line-height:
normal'><span style='font-family:"Arial",sans-serif;color:black'> </span></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:0in;margin-bottom:
0in;margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-17.85pt;line-height:normal;page-break-after:avoid'><a
name="_Ref535534624"><b><span style='font-family:"Arial",sans-serif'>18.<span
style='font:7.0pt "Times New Roman"'>&nbsp; </span></span></b><b><u><span
style='font-family:"Arial",sans-serif'>Disclaimer Of Warranty </span></u></b></a></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;line-height:
normal'><span style='font-family:"Arial",sans-serif;color:black'>THESE SERVICES
ARE PROVIDED BY COMPANY ON AN “AS IS” AND “AS AVAILABLE” BASIS. COMPANY MAKES
NO REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, AS TO THE
OPERATION OF THEIR SERVICES, OR THE INFORMATION, CONTENT OR MATERIALS INCLUDED
THEREIN. YOU EXPRESSLY AGREE THAT YOUR USE OF THESE SERVICES, THEIR CONTENT,
AND ANY SERVICES OR ITEMS OBTAINED FROM US IS AT YOUR SOLE RISK.</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;line-height:
normal'><span style='font-family:"Arial",sans-serif;color:black'> </span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;line-height:
normal;background:white'><span style='font-family:"Arial",sans-serif;
color:black'>NEITHER COMPANY NOR ANY PERSON ASSOCIATED WITH COMPANY MAKES ANY
WARRANTY OR REPRESENTATION WITH RESPECT TO THE COMPLETENESS, SECURITY,
RELIABILITY, QUALITY, ACCURACY, OR AVAILABILITY OF THE SERVICES. WITHOUT
LIMITING THE FOREGOING, NEITHER COMPANY NOR ANYONE ASSOCIATED WITH COMPANY
REPRESENTS OR WARRANTS THAT THE SERVICES, THEIR CONTENT, OR ANY SERVICES OR
ITEMS OBTAINED THROUGH THE SERVICES WILL BE ACCURATE, RELIABLE, ERROR-FREE, OR
UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT THE SERVICES OR THE SERVER
THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS OR THAT
THE SERVICES OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE SERVICES WILL
OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;line-height:
normal;background:white'><span style='font-family:"Arial",sans-serif;
color:black'> </span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;line-height:
normal;background:white'><span style='font-family:"Arial",sans-serif;
color:black'>COMPANY HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER
EXPRESS OR IMPLIED, STATUTORY, OR OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY
WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT, AND FITNESS FOR PARTICULAR
PURPOSE.</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;line-height:
normal;background:white'><span style='font-family:"Arial",sans-serif;
color:black'> </span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;line-height:
normal'><span style='font-family:"Arial",sans-serif;color:black'>THE FOREGOING
DOES NOT AFFECT ANY WARRANTIES WHICH CANNOT BE EXCLUDED OR LIMITED UNDER
APPLICABLE LAW.</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;line-height:
normal'><span style='font-family:"Arial",sans-serif;color:black'> </span></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:0in;margin-bottom:
0in;margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-17.85pt;line-height:normal;page-break-after:avoid'><a
name="_Ref535534639"><b><span style='font-family:"Arial",sans-serif'>19.<span
style='font:7.0pt "Times New Roman"'>&nbsp; </span></span></b><b><u><span
style='font-family:"Arial",sans-serif'>Limitation Of Liability</span></u></b></a></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;line-height:
normal'><span style='font-family:"Arial",sans-serif;color:black'>EXCEPT AS
PROHIBITED BY LAW, YOU WILL HOLD US AND OUR OFFICERS, DIRECTORS, EMPLOYEES, AND
AGENTS HARMLESS FOR ANY INDIRECT, PUNITIVE, SPECIAL, INCIDENTAL, OR
CONSEQUENTIAL DAMAGE, HOWEVER IT ARISES (INCLUDING ATTORNEYS' FEES AND ALL
RELATED COSTS AND EXPENSES OF LITIGATION AND ARBITRATION, OR AT TRIAL OR ON
APPEAL, IF ANY, WHETHER OR NOT LITIGATION OR ARBITRATION IS INSTITUTED), WHETHER
IN AN ACTION OF CONTRACT, NEGLIGENCE, OR OTHER TORTIOUS ACTION, OR ARISING OUT
OF OR IN CONNECTION WITH THIS AGREEMENT, INCLUDING WITHOUT LIMITATION ANY CLAIM
FOR PERSONAL INJURY OR PROPERTY DAMAGE, ARISING FROM THIS AGREEMENT AND ANY
VIOLATION BY YOU OF ANY FEDERAL, STATE, OR LOCAL LAWS, STATUTES, RULES, OR
REGULATIONS, EVEN IF COMPANY HAS BEEN PREVIOUSLY ADVISED OF THE POSSIBILITY OF
SUCH DAMAGE. EXCEPT AS PROHIBITED BY LAW, IF THERE IS LIABILITY FOUND ON THE
PART OF COMPANY, IT WILL BE LIMITED TO THE AMOUNT PAID FOR THE PRODUCTS AND/OR
SERVICES, AND UNDER NO CIRCUMSTANCES WILL THERE BE CONSEQUENTIAL OR PUNITIVE
DAMAGES. SOME STATES DO NOT ALLOW THE EXCLUSION OR LIMITATION OF PUNITIVE,
INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE PRIOR LIMITATION OR EXCLUSION MAY
NOT APPLY TO YOU.</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;line-height:
normal'><span style='font-family:"Arial",sans-serif;color:black'> </span></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:0in;margin-bottom:
0in;margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-17.85pt;line-height:normal;page-break-after:avoid'><b><span
style='font-family:"Arial",sans-serif'>20.<span style='font:7.0pt "Times New Roman"'>&nbsp;
</span></span></b><b><u><span style='font-family:"Arial",sans-serif'>Termination</span></u></b></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;line-height:
normal'><span style='font-family:"Arial",sans-serif;color:black'>We may
terminate or suspend your account and bar access to Service immediately,
without prior notice or liability, under our sole discretion, for any reason
whatsoever and without limitation, including but not limited to a breach of
Terms.</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;line-height:
normal'><span style='font-family:"Arial",sans-serif;color:black'> </span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;line-height:
normal'><span style='font-family:"Arial",sans-serif;color:black'>If you wish to
terminate your account, you may simply discontinue using Service.</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;line-height:
normal'><span style='font-family:"Arial",sans-serif;color:black'> </span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;line-height:
normal'><span style='font-family:"Arial",sans-serif;color:black'>All provisions
of Terms which by their nature should survive termination shall survive
termination, including, without limitation, ownership provisions, warranty
disclaimers, indemnity and limitations of liability.</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;line-height:
normal'><span style='font-family:"Arial",sans-serif;color:black'> </span></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:0in;margin-bottom:
0in;margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-17.85pt;line-height:normal;page-break-after:avoid'><b><span
style='font-family:"Arial",sans-serif'>21.<span style='font:7.0pt "Times New Roman"'>&nbsp;
</span></span></b><b><u><span style='font-family:"Arial",sans-serif'>Governing
Law</span></u></b></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;line-height:
normal'><span style='font-family:"Arial",sans-serif;color:black'>These Terms
shall be governed and construed in accordance with the laws of&nbsp;State of
Georgia&nbsp;without regard to its conflict of law provisions.<a
name="_Hlk2089641"></a><a name="_Hlk2089622"></a><a name="_Hlk2098739"></a></span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;line-height:
normal'><span style='font-family:"Arial",sans-serif;color:black'> </span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;line-height:
normal'><span style='font-family:"Arial",sans-serif;color:black'>Our failure to
enforce any right or provision of these Terms will not be considered a waiver
of those rights. If any provision of these Terms is held to be invalid or
unenforceable by a court, the remaining provisions of these Terms will remain
in effect. These Terms constitute the entire agreement between us regarding our
Service and supersede and replace any prior agreements we might have had
between us regarding Service.</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;line-height:
normal'><span style='font-family:"Arial",sans-serif;color:black'> </span></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:0in;margin-bottom:
0in;margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-17.85pt;line-height:normal;page-break-after:avoid'><b><span
style='font-family:"Arial",sans-serif'>22.<span style='font:7.0pt "Times New Roman"'>&nbsp;
</span></span></b><b><u><span style='font-family:"Arial",sans-serif'>Changes To
Service</span></u></b></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;line-height:
normal;background:white'><span style='font-family:"Arial",sans-serif;
color:black'>We reserve the right to withdraw or amend our Service, and any
service or material we provide via Service, in our sole discretion without
notice. We will not be liable if for any reason all or any part of Service is
unavailable at any time or for any period. From time to time, we may restrict
access to some parts of Service, or the entire Service, to users, including
registered users.</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;line-height:
normal;background:white'><span style='font-family:"Arial",sans-serif;
color:black'> </span></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:0in;margin-bottom:
0in;margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-17.85pt;line-height:normal;page-break-after:avoid'><b><span
style='font-family:"Arial",sans-serif'>23.<span style='font:7.0pt "Times New Roman"'>&nbsp;
</span></span></b><b><u><span style='font-family:"Arial",sans-serif'>Amendments
To Terms</span></u></b></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;line-height:
normal'><span style='font-family:"Arial",sans-serif;color:black'>We may amend
Terms at any time by posting the amended terms on this site. It is your
responsibility to review these Terms periodically.</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;line-height:
normal'><span style='font-family:"Arial",sans-serif;color:black'> </span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;line-height:
normal'><span style='font-family:"Arial",sans-serif;color:black'>Your continued
use of the Platform following the posting of revised Terms means that you
accept and agree to the changes. You are expected to check this page frequently
so you are aware of any changes, as they are binding on you.</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;line-height:
normal'><span style='font-family:"Arial",sans-serif;color:black'> </span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;line-height:
normal'><span style='font-family:"Arial",sans-serif;color:black'>By continuing
to access or use our Service after any revisions become effective, you agree to
be bound by the revised terms. If you do not agree to the new terms, you are no
longer authorized to use Service.</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;line-height:
normal'><span style='font-family:"Arial",sans-serif;color:black'> </span></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:0in;margin-bottom:
0in;margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-17.85pt;line-height:normal;page-break-after:avoid'><a
name="_Ref535534689"><b><span style='font-family:"Arial",sans-serif'>24.<span
style='font:7.0pt "Times New Roman"'>&nbsp; </span></span></b><b><u><span
style='font-family:"Arial",sans-serif'>Waiver And Severability</span></u></b></a></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;line-height:
normal;background:white'><span style='font-family:"Arial",sans-serif;
color:black'>No waiver by Company of any term or condition set forth in Terms
shall be deemed a further or continuing waiver of such term or condition or a
waiver of any other term or condition, and any failure of Company to assert a
right or provision under Terms shall not constitute a waiver of such right or provision.</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;line-height:
normal;background:white'><span style='font-family:"Arial",sans-serif;
color:black'> </span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;line-height:
normal;background:white'><span style='font-family:"Arial",sans-serif;
color:black'>If any provision of Terms is held by a court or other tribunal of
competent jurisdiction to be invalid, illegal or unenforceable for any reason,
such provision shall be eliminated or limited to the minimum extent such that
the remaining provisions of Terms will continue in full force and effect.</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;line-height:
normal;background:white'><span style='font-family:"Arial",sans-serif;
color:black'> </span></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:0in;margin-bottom:
0in;margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-17.85pt;line-height:normal;page-break-after:avoid'><a
name="_Ref535534766"><b><span style='font-family:"Arial",sans-serif'>25.<span
style='font:7.0pt "Times New Roman"'>&nbsp; </span></span></b><b><u><span
style='font-family:"Arial",sans-serif'>Acknowledgement</span></u></b></a></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;line-height:
normal'><span style='font-family:"Arial",sans-serif;color:black'>BY USING
SERVICE OR OTHER SERVICES PROVIDED BY US, YOU ACKNOWLEDGE THAT YOU HAVE READ
THESE TERMS OF SERVICE AND AGREE TO BE BOUND BY THEM.</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;line-height:
normal'><span style='font-family:"Arial",sans-serif;color:black'> </span></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:0in;margin-bottom:
0in;margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-17.85pt;line-height:normal;page-break-after:avoid'><b><span
style='font-family:"Arial",sans-serif'>26.<span style='font:7.0pt "Times New Roman"'>&nbsp;
</span></span></b><b><u><span style='font-family:"Arial",sans-serif'>Contact Us</span></u></b></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;line-height:
normal'><span style='font-family:"Arial",sans-serif;color:black'>Please send
your feedback, comments, requests for technical support:</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;line-height:
normal'><span style='font-family:"Arial",sans-serif;color:black'>By email:
support@mist.greenknightlabs.com.</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;line-height:
normal'><span style='font-family:"Arial",sans-serif;color:black'>By visiting
this page on our website: https://web.mist.greenknightlabs.com/contact.</span></p>

</div>

</body>

</html>

    `;

    return (
        <Box sx={{ bgcolor: 'white' }} p={2}>
            <div style={{ color: 'black' }} dangerouslySetInnerHTML={{ __html: html }} />
        </Box>
    );
}
