import { Route } from '../app/router';
import PostView from './views/post.view';

export const postsRoutes: Array<Route> = [
    // posts
    {
        key: 'post',
        title: 'Post',
        path: '/posts/:id',
        enabled: true,
        component: PostView,
    },
];
