/* eslint-disable no-useless-escape */
import { Box } from '@mui/system';

/* eslint-disable no-irregular-whitespace */
export default function PrivacyView() {
    const html = `
    <html>

<head>
<meta http-equiv=Content-Type content="text/html; charset=utf-8">
<meta name=Generator content="Microsoft Word 15 (filtered)">
<style>
<!--
 /* Font Definitions */
 @font-face
	{font-family:Courier;
	panose-1:2 7 4 9 2 2 5 2 4 4;}
@font-face
	{font-family:Wingdings;
	panose-1:5 0 0 0 0 0 0 0 0 0;}
@font-face
	{font-family:"MS Mincho";
	panose-1:2 2 6 9 4 2 5 8 3 4;}
@font-face
	{font-family:"MS Gothic";
	panose-1:2 11 6 9 7 2 5 8 2 4;}
@font-face
	{font-family:"Cambria Math";
	panose-1:2 4 5 3 5 4 6 3 2 4;}
@font-face
	{font-family:"\@MS Gothic";
	panose-1:2 11 6 9 7 2 5 8 2 4;}
@font-face
	{font-family:"\@MS Mincho";
	panose-1:2 2 6 9 4 2 5 8 3 4;}
 /* Style Definitions */
 p.MsoNormal, li.MsoNormal, div.MsoNormal
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:10.0pt;
	margin-left:0in;
	page-break-after:avoid;
	font-size:11.0pt;
	font-family:"Times New Roman",serif;}
h1
	{mso-style-link:"Heading 1 Char";
	margin-top:24.0pt;
	margin-right:0in;
	margin-bottom:24.0pt;
	margin-left:0in;
	page-break-after:avoid;
	font-size:20.0pt;
	font-family:"Times New Roman",serif;
	font-weight:bold;}
p.MsoSubtitle, li.MsoSubtitle, div.MsoSubtitle
	{mso-style-link:"Subtitle Char";
	margin-top:0in;
	margin-right:0in;
	margin-bottom:6.0pt;
	margin-left:0in;
	page-break-after:avoid;
	font-size:11.0pt;
	font-family:"Times New Roman",serif;
	letter-spacing:.75pt;
	font-weight:bold;}
a:link, span.MsoHyperlink
	{color:blue;
	text-decoration:underline;}
p.MsoListParagraph, li.MsoListParagraph, div.MsoListParagraph
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:10.0pt;
	margin-left:.5in;
	page-break-after:avoid;
	font-size:11.0pt;
	font-family:"Times New Roman",serif;}
p.MsoListParagraphCxSpFirst, li.MsoListParagraphCxSpFirst, div.MsoListParagraphCxSpFirst
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:0in;
	margin-left:.5in;
	page-break-after:avoid;
	font-size:11.0pt;
	font-family:"Times New Roman",serif;}
p.MsoListParagraphCxSpMiddle, li.MsoListParagraphCxSpMiddle, div.MsoListParagraphCxSpMiddle
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:0in;
	margin-left:.5in;
	page-break-after:avoid;
	font-size:11.0pt;
	font-family:"Times New Roman",serif;}
p.MsoListParagraphCxSpLast, li.MsoListParagraphCxSpLast, div.MsoListParagraphCxSpLast
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:10.0pt;
	margin-left:.5in;
	page-break-after:avoid;
	font-size:11.0pt;
	font-family:"Times New Roman",serif;}
span.Heading1Char
	{mso-style-name:"Heading 1 Char";
	mso-style-link:"Heading 1";
	font-family:"Times New Roman",serif;
	font-weight:bold;}
span.SubtitleChar
	{mso-style-name:"Subtitle Char";
	mso-style-link:Subtitle;
	font-family:"Times New Roman",serif;
	letter-spacing:.75pt;
	font-weight:bold;}
.MsoChpDefault
	{font-family:"Cambria",serif;}
.MsoPapDefault
	{margin-bottom:10.0pt;
	line-height:115%;}
 /* Page Definitions */
 @page WordSection1
	{size:8.5in 11.0in;
	margin:1.0in 1.0in 1.0in 1.0in;}
div.WordSection1
	{page:WordSection1;}
 /* List Definitions */
 ol
	{margin-bottom:0in;}
ul
	{margin-bottom:0in;}
-->
</style>

</head>

<body lang=EN-US link=blue vlink=purple style='word-wrap:break-word'>

<div class=WordSection1>

<h1 align=center style='margin:0in;text-align:center;page-break-after:auto'><span
style='font-size:11.0pt;font-family:"Arial",sans-serif'>PRIVACY POLICY</span></h1>

<p class=MsoNormal style='margin-bottom:0in;page-break-after:auto'> </p>

<p class=MsoNormal style='margin-bottom:0in;page-break-after:auto'><span
style='font-family:"Arial",sans-serif'>Effective date: 11/13/2022</span></p>

<p class=MsoNormal style='margin-bottom:0in;page-break-after:auto'><span
style='font-family:"Arial",sans-serif'> </span></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:0in;margin-bottom:
0in;margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-17.85pt'><b><span style='font-family:"Arial",sans-serif'>1.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span></b><b><u><span
style='font-family:"Arial",sans-serif'>Introduction</span></u></b></p>

<p class=MsoNormal style='margin-bottom:0in;text-align:justify;text-justify:
inter-ideograph'><b><u><span style='font-family:"Arial",sans-serif'> </span></u></b></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;
page-break-after:auto'><span style='font-family:"Arial",sans-serif'>Yoan
Dubuc&nbsp;(“us”, “we”, or “our”) operates&nbsp;Mist mobile
application&nbsp;(hereinafter referred to as “<b>Service</b>”).</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;
page-break-after:auto'><span style='font-family:"Arial",sans-serif'> </span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;
page-break-after:auto'><span style='font-family:"Arial",sans-serif'>Our Privacy
Policy governs your visit to&nbsp;Mist mobile application, and explains how we
collect, safeguard and disclose information that results from your use of our
Service. </span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;
page-break-after:auto'><span style='font-family:"Arial",sans-serif'> </span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;
page-break-after:auto'><span style='font-family:"Arial",sans-serif'>We use your
data to provide and improve Service. By using Service, you agree to the
collection and use of information in accordance with this policy. Unless
otherwise defined in this Privacy Policy, the terms used in this Privacy Policy
have the same meanings as in our Terms and Conditions.</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;
page-break-after:auto'><span style='font-family:"Arial",sans-serif'> </span></p>

<p class=MsoListParagraphCxSpLast style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;
page-break-after:auto'><span style='font-family:"Arial",sans-serif'>Our Terms
and Conditions (“<b>Terms</b>”) govern all use of our Service and together with
the Privacy Policy constitutes your agreement with us (“<b>agreement</b>”).</span></p>

<p class=MsoNormal style='margin-bottom:0in;text-align:justify;text-justify:
inter-ideograph;page-break-after:auto'><span style='font-family:"Arial",sans-serif'> </span></p>

<p class=MsoListParagraphCxSpFirst style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-17.85pt'><b><span style='font-family:"Arial",sans-serif'>2.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span></b><b><u><span
style='font-family:"Arial",sans-serif'>Definitions</span></u></b></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph'><b><u><span
style='font-family:"Arial",sans-serif'> </span></u></b></p>

<p class=MsoListParagraphCxSpLast style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;
page-break-after:auto'><b><span style='font-family:"Arial",sans-serif'>SERVICE&nbsp;</span></b><span
style='font-family:"Arial",sans-serif'>means the&nbsp;Mist mobile
application&nbsp;operated by&nbsp;Yoan Dubuc.</span></p>

<p class=MsoNormal style='margin-bottom:0in;page-break-after:auto'><span
style='font-family:"Arial",sans-serif'> </span></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:0in;margin-bottom:
0in;margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;
page-break-after:auto'><b><span style='font-family:"Arial",sans-serif'>PERSONAL
DATA</span></b><span style='font-family:"Arial",sans-serif'>&nbsp;means data
about a living individual who can be identified from those data (or from those
and other information either in our possession or likely to come into our
possession).</span></p>

<p class=MsoNormal style='margin-bottom:0in;page-break-after:auto'><span
style='font-family:"Arial",sans-serif'> </span></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:0in;margin-bottom:
0in;margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;
page-break-after:auto'><b><span style='font-family:"Arial",sans-serif'>USAGE
DATA</span></b><span style='font-family:"Arial",sans-serif'>&nbsp;is data
collected automatically either generated by the use of Service or from Service
infrastructure itself (for example, the duration of a page visit).</span></p>

<p class=MsoNormal style='margin-bottom:0in;page-break-after:auto'><span
style='font-family:"Arial",sans-serif'> </span></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:0in;margin-bottom:
0in;margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;
page-break-after:auto'><b><span style='font-family:"Arial",sans-serif'>COOKIES</span></b><span
style='font-family:"Arial",sans-serif'>&nbsp;are small files stored on your
device (computer or mobile device).</span></p>

<p class=MsoNormal style='margin-bottom:0in;page-break-after:auto'><span
style='font-family:"Arial",sans-serif'> </span></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:0in;margin-bottom:
0in;margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;
page-break-after:auto'><b><span style='font-family:"Arial",sans-serif'>DATA
CONTROLLER</span></b><span lang=UK style='font-family:"Arial",sans-serif'>&nbsp;means
a natural or legal person who (either alone or jointly or in common with other
persons) determines the purposes for which and the manner in which any personal
data are, or are to be, processed. For the purpose of this Privacy Policy, we
are a Data Controller of your data.</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;page-break-after:
auto'><span style='font-family:"Arial",sans-serif'> </span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;page-break-after:
auto'><b><span style='font-family:"Arial",sans-serif'>DATA PROCESSORS (OR
SERVICE PROVIDERS)</span></b><span lang=UK style='font-family:"Arial",sans-serif'>&nbsp;means
any natural or legal person who processes the data on behalf of the Data
Controller. We may use the services of various Service Providers in order to
process your data more effectively.</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;page-break-after:
auto'><span style='font-family:"Arial",sans-serif'> </span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;page-break-after:
auto'><b><span style='font-family:"Arial",sans-serif'>DATA SUBJECT&nbsp;</span></b><span
style='font-family:"Arial",sans-serif'>is any living individual who is the
subject of Personal Data.</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;page-break-after:
auto'><span style='font-family:"Arial",sans-serif'> </span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;page-break-after:
auto'><b><span style='font-family:"Arial",sans-serif'>THE USER&nbsp;</span></b><span
style='font-family:"Arial",sans-serif'>is the individual using our Service. The
User corresponds to the Data Subject, who is the subject of Personal Data.</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;page-break-after:
auto'><span style='font-family:"Arial",sans-serif'> </span></p>

<p class=MsoListParagraphCxSpFirst style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-17.85pt'><b><span style='font-family:"Arial",sans-serif'>3.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span></b><b><u><span
style='font-family:"Arial",sans-serif'>Information Collection and Use</span></u></b></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph'><b><u><span
style='font-family:"Arial",sans-serif'> </span></u></b></p>

<p class=MsoListParagraphCxSpLast style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;
page-break-after:auto'><span style='font-family:"Arial",sans-serif'>We collect
several different types of information for various purposes to provide and improve
our Service to you.</span></p>

<p class=MsoNormal style='margin-bottom:0in;page-break-after:auto'><span
style='font-family:"Arial",sans-serif'> </span></p>

<p class=MsoListParagraphCxSpFirst style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-17.85pt'><b><span style='font-family:"Arial",sans-serif'>4.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span></b><b><u><span
style='font-family:"Arial",sans-serif'>Types of Data Collected</span></u></b></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph'><b><u><span
style='font-family:"Arial",sans-serif'> </span></u></b></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph'><b><span
style='font-family:"Arial",sans-serif'>Personal Data</span></b></p>

<p class=MsoListParagraphCxSpLast style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph'><span
style='font-family:"Arial",sans-serif'>While using our Service, we may ask you
to provide us with certain personally identifiable information that can be used
to contact or identify you (“<b>Personal Data</b>”). Personally identifiable
information may include, but is not limited to:</span></p>

<p class=MsoNormal style='margin-bottom:0in;text-align:justify;text-justify:
inter-ideograph;page-break-after:auto'><span style='font-family:"Arial",sans-serif'> </span></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:0in;margin-bottom:
0in;margin-left:35.7pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-17.7pt;page-break-after:auto'><span style='font-family:"Arial",sans-serif'>(a)<span
style='font:7.0pt "Times New Roman"'>&nbsp; </span></span><span
style='font-family:"Arial",sans-serif'>Email address</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:.25in;text-align:justify;text-justify:inter-ideograph;page-break-after:
auto'><span style='font-family:"Arial",sans-serif'> </span></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:0in;margin-bottom:
0in;margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;
page-break-after:auto'><span style='font-family:"Arial",sans-serif'>We may use
your Personal Data to contact you with newsletters, marketing or promotional
materials and other information that may be of interest to you. You may opt out
of receiving any, or all, of these communications from us&nbsp;by following the
unsubscribe link.</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:14.2pt;text-align:justify;text-justify:inter-ideograph;page-break-after:
auto'><span style='font-family:"Arial",sans-serif'> </span></p>

<p class=MsoListParagraphCxSpFirst style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph'><b><span
style='font-family:"Arial",sans-serif'>Usage Data</span></b></p>

<p class=MsoListParagraphCxSpLast style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;
page-break-after:auto'><span style='font-family:"Arial",sans-serif'>We may also
collect information that your browser sends whenever you visit our Service or
when you access Service by or through a mobile device (“<b>Usage Data</b>”).</span></p>

<p class=MsoNormal style='margin-bottom:0in;text-align:justify;text-justify:
inter-ideograph;page-break-after:auto'><span style='font-family:"Arial",sans-serif'> </span></p>

<p class=MsoListParagraphCxSpFirst style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;
page-break-after:auto'><span style='font-family:"Arial",sans-serif'>This Usage
Data may include information such as your computer's Internet Protocol address
(e.g. IP address), browser type, browser version, the pages of our Service that
you visit, the time and date of your visit, the time spent on those pages,
unique device identifiers and other diagnostic data.</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;
page-break-after:auto'><span style='font-family:"Arial",sans-serif'> </span></p>

<p class=MsoListParagraphCxSpLast style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;
page-break-after:auto'><span style='font-family:"Arial",sans-serif'>When you
access Service with a mobile device, this Usage Data may include information
such as the type of mobile device you use, your mobile device unique ID, the IP
address of your mobile device, your mobile operating system, the type of mobile
Internet browser you use, unique device identifiers and other diagnostic data.</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:14.2pt;text-align:justify;text-justify:inter-ideograph;page-break-after:
auto'><span style='font-family:"Arial",sans-serif'> </span></p>

<p class=MsoListParagraphCxSpFirst style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph'><b><span
style='font-family:"Arial",sans-serif'>Tracking Cookies Data</span></b></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;
page-break-after:auto'><span style='font-family:"Arial",sans-serif'>We use
cookies and similar tracking technologies to track the activity on our Service
and we hold certain information.</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;
page-break-after:auto'><span style='font-family:"Arial",sans-serif'> </span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;
page-break-after:auto'><span style='font-family:"Arial",sans-serif'>Cookies are
files with a small amount of data which may include an anonymous unique
identifier. Cookies are sent to your browser from a website and stored on your
device. Other tracking technologies are also used such as beacons, tags and
scripts to collect and track information and to improve and analyze our
Service.</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;
page-break-after:auto'><span style='font-family:"Arial",sans-serif'> </span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;
page-break-after:auto'><span style='font-family:"Arial",sans-serif'>You can
instruct your browser to refuse all cookies or to indicate when a cookie is
being sent. However, if you do not accept cookies, you may not be able to use
some portions of our Service.</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;
page-break-after:auto'><span style='font-family:"Arial",sans-serif'> </span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph'><span
style='font-family:"Arial",sans-serif'>Examples of Cookies we use:</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph'><span
style='font-family:"Arial",sans-serif'> </span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:35.7pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-17.7pt;page-break-after:auto'><span style='font-family:"Arial",sans-serif'>(a)<span
style='font:7.0pt "Times New Roman"'>&nbsp; </span></span><b><span
style='font-family:"Arial",sans-serif'>Session Cookies:</span></b><span
style='font-family:"Arial",sans-serif'>&nbsp;We use Session Cookies to operate
our Service.</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;
page-break-after:auto'><span style='font-family:"Arial",sans-serif'> </span></p>

<p class=MsoListParagraphCxSpLast style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:35.7pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-17.7pt;page-break-after:auto'><span style='font-family:"Arial",sans-serif'>(b)<span
style='font:7.0pt "Times New Roman"'>&nbsp; </span></span><b><span
style='font-family:"Arial",sans-serif'>Preference Cookies:</span></b><span
style='font-family:"Arial",sans-serif'>&nbsp;We use Preference Cookies to
remember your preferences and various settings.</span></p>

<p class=MsoNormal style='margin-bottom:0in;text-align:justify;text-justify:
inter-ideograph;page-break-after:auto'><span style='font-family:"Arial",sans-serif'> </span></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:0in;margin-bottom:
0in;margin-left:35.7pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-17.7pt;page-break-after:auto'><span style='font-family:"Arial",sans-serif'>(c)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span></span><b><span
style='font-family:"Arial",sans-serif'>Security Cookies:</span></b><span
style='font-family:"Arial",sans-serif'>&nbsp;We use Security Cookies for
security purposes.</span></p>

<p class=MsoNormal style='margin-bottom:0in;text-align:justify;text-justify:
inter-ideograph;page-break-after:auto'><span style='font-family:"Arial",sans-serif'> </span></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:0in;margin-bottom:
0in;margin-left:35.7pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-17.7pt;page-break-after:auto'><span style='font-family:"Arial",sans-serif'>(d)<span
style='font:7.0pt "Times New Roman"'>&nbsp; </span></span><b><span
style='font-family:"Arial",sans-serif'>Advertising Cookies:</span></b><span
style='font-family:"Arial",sans-serif'>&nbsp;Advertising Cookies are used to
serve you with advertisements that may be relevant to you and your interests.</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:.25in;text-align:justify;text-justify:inter-ideograph;page-break-after:
auto'><span style='font-family:"Arial",sans-serif'> </span></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:0in;margin-bottom:
0in;margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-17.85pt'><b><span style='font-family:"Arial",sans-serif'>5.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span></b><b><u><span
style='font-family:"Arial",sans-serif'>Use of Data</span></u></b></p>

<p class=MsoNormal style='margin-bottom:0in;text-align:justify;text-justify:
inter-ideograph'><b><u><span style='font-family:"Arial",sans-serif'> </span></u></b></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:0in;margin-bottom:
0in;margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph'><span
style='font-family:"Arial",sans-serif'>Yoan Dubuc&nbsp;uses the collected data
for various purposes:</span></p>

<p class=MsoNormal style='margin-bottom:0in;text-align:justify;text-justify:
inter-ideograph'><span style='font-family:"Arial",sans-serif'> </span></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:0in;margin-bottom:
0in;margin-left:35.7pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-17.7pt;page-break-after:auto'><span style='font-family:"Arial",sans-serif'>(a)<span
style='font:7.0pt "Times New Roman"'>&nbsp; </span></span><span
style='font-family:"Arial",sans-serif'>to provide and maintain our Service;</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:.25in;text-align:justify;text-justify:inter-ideograph;page-break-after:
auto'><span style='font-family:"Arial",sans-serif'> </span></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:0in;margin-bottom:
0in;margin-left:35.7pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-17.7pt;page-break-after:auto'><span style='font-family:"Arial",sans-serif'>(b)<span
style='font:7.0pt "Times New Roman"'>&nbsp; </span></span><span
style='font-family:"Arial",sans-serif'>to notify you about changes to our
Service; </span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:.25in;text-align:justify;text-justify:inter-ideograph;page-break-after:
auto'><span style='font-family:"Arial",sans-serif'> </span></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:0in;margin-bottom:
0in;margin-left:35.7pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-17.7pt;page-break-after:auto'><span style='font-family:"Arial",sans-serif'>(c)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span></span><span
style='font-family:"Arial",sans-serif'>to allow you to participate in
interactive features of our Service when you choose to do so; </span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:.25in;text-align:justify;text-justify:inter-ideograph;page-break-after:
auto'><span style='font-family:"Arial",sans-serif'> </span></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:0in;margin-bottom:
0in;margin-left:35.7pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-17.7pt;page-break-after:auto'><span style='font-family:"Arial",sans-serif'>(d)<span
style='font:7.0pt "Times New Roman"'>&nbsp; </span></span><span
style='font-family:"Arial",sans-serif'>to provide customer support; </span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:.25in;text-align:justify;text-justify:inter-ideograph;page-break-after:
auto'><span style='font-family:"Arial",sans-serif'> </span></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:0in;margin-bottom:
0in;margin-left:35.7pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-17.7pt;page-break-after:auto'><span style='font-family:"Arial",sans-serif'>(e)<span
style='font:7.0pt "Times New Roman"'>&nbsp; </span></span><span
style='font-family:"Arial",sans-serif'>to gather analysis or valuable
information so that we can improve our Service; </span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:.25in;text-align:justify;text-justify:inter-ideograph;page-break-after:
auto'><span style='font-family:"Arial",sans-serif'> </span></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:0in;margin-bottom:
0in;margin-left:35.7pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-17.7pt;page-break-after:auto'><span style='font-family:"Arial",sans-serif'>(f)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><span
style='font-family:"Arial",sans-serif'>to monitor the usage of our Service;</span></p>

<p class=MsoNormal style='margin-bottom:0in;text-align:justify;text-justify:
inter-ideograph;page-break-after:auto'><span style='font-family:"Arial",sans-serif'> </span></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:0in;margin-bottom:
0in;margin-left:35.7pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-17.7pt;page-break-after:auto'><span style='font-family:"Arial",sans-serif'>(g)<span
style='font:7.0pt "Times New Roman"'>&nbsp; </span></span><span
style='font-family:"Arial",sans-serif'>to detect, prevent and address technical
issues;</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:.25in;text-align:justify;text-justify:inter-ideograph;page-break-after:
auto'><span style='font-family:"Arial",sans-serif'> </span></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:0in;margin-bottom:
0in;margin-left:35.7pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-17.7pt;page-break-after:auto'><span style='font-family:"Arial",sans-serif'>(h)<span
style='font:7.0pt "Times New Roman"'>&nbsp; </span></span><span
style='font-family:"Arial",sans-serif'>to fulfill any other purpose for which
you provide it;</span></p>

<p class=MsoNormal style='margin-bottom:0in;text-align:justify;text-justify:
inter-ideograph;page-break-after:auto'><span style='font-family:"Arial",sans-serif'> </span></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:0in;margin-bottom:
0in;margin-left:35.7pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-17.7pt;page-break-after:auto'><span style='font-family:"Arial",sans-serif'>(i)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><span
style='font-family:"Arial",sans-serif'>to carry out our obligations and enforce
our rights arising from any contracts entered into between you and us,
including for billing and collection;</span></p>

<p class=MsoNormal style='margin-bottom:0in;text-align:justify;text-justify:
inter-ideograph;page-break-after:auto'><span style='font-family:"Arial",sans-serif'> </span></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:0in;margin-bottom:
0in;margin-left:35.7pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-17.7pt;page-break-after:auto'><span style='font-family:"Arial",sans-serif'>(j)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><span
style='font-family:"Arial",sans-serif'>to provide you with notices about your
account and/or subscription, including expiration and renewal notices,
email-instructions, etc.;</span></p>

<p class=MsoNormal style='margin-bottom:0in;text-align:justify;text-justify:
inter-ideograph;page-break-after:auto'><span style='font-family:"Arial",sans-serif'> </span></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:0in;margin-bottom:
0in;margin-left:35.7pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-17.7pt;page-break-after:auto'><span style='font-family:"Arial",sans-serif'>(k)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span></span><span
style='font-family:"Arial",sans-serif'>to provide you with news, special offers
and general information about other goods, services and events which we offer
that are similar to those that you have already purchased or enquired about
unless you have opted not to receive such information;</span></p>

<p class=MsoNormal style='margin-bottom:0in;text-align:justify;text-justify:
inter-ideograph;page-break-after:auto'><span style='font-family:"Arial",sans-serif'> </span></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:0in;margin-bottom:
0in;margin-left:35.7pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-17.7pt;page-break-after:auto'><span style='font-family:"Arial",sans-serif'>(l)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><span
style='font-family:"Arial",sans-serif'>in any other way we may describe when
you provide the information;</span></p>

<p class=MsoNormal style='margin-bottom:0in;text-align:justify;text-justify:
inter-ideograph;page-break-after:auto'><span style='font-family:"Arial",sans-serif'> </span></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:0in;margin-bottom:
0in;margin-left:35.7pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-17.7pt;page-break-after:auto'><span style='font-family:"Arial",sans-serif'>(m)<span
style='font:7.0pt "Times New Roman"'> </span></span><span style='font-family:
"Arial",sans-serif'>for any other purpose with your consent. </span></p>

<p class=MsoNormal style='margin-bottom:0in;text-align:justify;text-justify:
inter-ideograph;page-break-after:auto'><span style='font-family:"Arial",sans-serif'> </span></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:0in;margin-bottom:
0in;margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-17.85pt'><b><span style='font-family:"Arial",sans-serif'>6.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span></b><b><u><span
style='font-family:"Arial",sans-serif'>Retention of Data</span></u></b></p>

<p class=MsoNormal style='margin-bottom:0in;text-align:justify;text-justify:
inter-ideograph'><b><u><span style='font-family:"Arial",sans-serif'> </span></u></b></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:0in;margin-bottom:
0in;margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;
page-break-after:auto'><span style='font-family:"Arial",sans-serif'>We will
retain your Personal Data only for as long as is necessary for the purposes set
out in this Privacy Policy. We will retain and use your Personal Data to the
extent necessary to comply with our legal obligations (for example, if we are
required to retain your data to comply with applicable laws), resolve disputes,
and enforce our legal agreements and policies.</span></p>

<p class=MsoNormal style='margin-bottom:0in;text-align:justify;text-justify:
inter-ideograph;page-break-after:auto'><span style='font-family:"Arial",sans-serif'> </span></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:0in;margin-bottom:
0in;margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;
page-break-after:auto'><span style='font-family:"Arial",sans-serif'>We will
also retain Usage Data for internal analysis purposes. Usage Data is generally
retained for a shorter period, except when this data is used to strengthen the
security or to improve the functionality of our Service, or we are legally
obligated to retain this data for longer time periods.</span></p>

<p class=MsoNormal style='margin-bottom:0in;page-break-after:auto'><span
style='font-family:"Arial",sans-serif'> </span></p>

<p class=MsoListParagraphCxSpFirst style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-17.85pt'><b><span style='font-family:"Arial",sans-serif'>7.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span></b><b><u><span
style='font-family:"Arial",sans-serif'>Transfer of Data</span></u></b></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph'><b><u><span
style='font-family:"Arial",sans-serif'> </span></u></b></p>

<p class=MsoListParagraphCxSpLast style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;
page-break-after:auto'><span style='font-family:"Arial",sans-serif'>Your
information, including Personal Data, may be transferred to – and maintained on
– computers located outside of your state, province, country or other
governmental jurisdiction where the data protection laws may differ from those
of your jurisdiction.</span></p>

<p class=MsoNormal style='margin-bottom:0in;text-align:justify;text-justify:
inter-ideograph;page-break-after:auto'><span style='font-family:"Arial",sans-serif'> </span></p>

<p class=MsoListParagraphCxSpFirst style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;
page-break-after:auto'><span style='font-family:"Arial",sans-serif'>If you are
located outside United States and choose to provide information to us, please
note that we transfer the data, including Personal Data, to United States and
process it there.</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;
page-break-after:auto'><span style='font-family:"Arial",sans-serif'> </span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;
page-break-after:auto'><span style='font-family:"Arial",sans-serif'>Your consent
to this Privacy Policy followed by your submission of such information
represents your agreement to that transfer.</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;
page-break-after:auto'><span style='font-family:"Arial",sans-serif'> </span></p>

<p class=MsoListParagraphCxSpLast style='margin-top:0in;margin-right:.2pt;
margin-bottom:0in;margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;
page-break-after:auto'><span style='font-family:"Arial",sans-serif'>Yoan
Dubuc&nbsp;will take all the steps reasonably necessary to ensure that your
data is treated securely and in accordance with this Privacy Policy and no
transfer of your Personal Data will take place to an organisation or a country
unless there are adequate controls in place including the security of your data
and other personal information.</span></p>

<p class=MsoNormal style='margin-bottom:0in;page-break-after:auto'><span
style='font-family:"Arial",sans-serif'> </span></p>

<p class=MsoListParagraphCxSpFirst style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-17.85pt'><b><span style='font-family:"Arial",sans-serif'>8.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span></b><b><u><span
style='font-family:"Arial",sans-serif'>Disclosure of Data</span></u></b></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph'><b><u><span
style='font-family:"Arial",sans-serif'> </span></u></b></p>

<p class=MsoListParagraphCxSpLast style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph'><span
style='font-family:"Arial",sans-serif'>We may disclose personal information
that we collect, or you provide:</span></p>

<p class=MsoNormal style='margin-bottom:0in;page-break-after:auto'><span
style='font-family:"Arial",sans-serif'> </span></p>

<p class=MsoListParagraphCxSpFirst style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:35.7pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-17.7pt'><span style='font-family:"Arial",sans-serif'>(a)<span
style='font:7.0pt "Times New Roman"'>&nbsp; </span></span><b><span
style='font-family:"Arial",sans-serif'>Disclosure for Law Enforcement.</span></b></p>

<p class=MsoListParagraphCxSpLast style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:35.7pt;text-align:justify;text-justify:inter-ideograph;
page-break-after:auto'><span style='font-family:"Arial",sans-serif'>Under
certain circumstances, we may be required to disclose your Personal Data if
required to do so by law or in response to valid requests by public
authorities.</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:21.3pt;page-break-after:auto'><span style='font-family:"Arial",sans-serif'> </span></p>

<p class=MsoListParagraphCxSpFirst style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:35.7pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-17.7pt'><span style='font-family:"Arial",sans-serif'>(b)<span
style='font:7.0pt "Times New Roman"'>&nbsp; </span></span><b><span
style='font-family:"Arial",sans-serif'>Business Transaction.</span></b></p>

<p class=MsoListParagraphCxSpLast style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:35.7pt;text-align:justify;text-justify:inter-ideograph;
page-break-after:auto'><span style='font-family:"Arial",sans-serif'>If we or
our subsidiaries are involved in a merger, acquisition or asset sale, your
Personal Data may be transferred.</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:21.25pt;page-break-after:auto'><span style='font-family:"Arial",sans-serif'> </span></p>

<p class=MsoListParagraphCxSpFirst style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:35.45pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-17.6pt'><span style='font-family:"Arial",sans-serif'>(c)<span
style='font:7.0pt "Times New Roman"'>&nbsp; </span></span><b><span
style='font-family:"Arial",sans-serif'>Other cases. We may disclose your
information also:</span></b></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:35.45pt;text-align:justify;text-justify:inter-ideograph'><b><span
style='font-family:"Arial",sans-serif'> </span></b></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:.75in;text-align:justify;text-justify:inter-ideograph;
text-indent:-.25in;page-break-after:auto'><span style='font-family:"Arial",sans-serif'>(i)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><span
style='font-family:"Arial",sans-serif'>to our subsidiaries and affiliates;</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:.75in;text-align:justify;text-justify:inter-ideograph;
page-break-after:auto'><span style='font-family:"Arial",sans-serif'> </span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:.75in;text-align:justify;text-justify:inter-ideograph;
text-indent:-.25in;page-break-after:auto'><span style='font-family:"Arial",sans-serif'>(ii)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span></span><span
style='font-family:"Arial",sans-serif'>to fulfill the purpose for which you
provide it;</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:.75in;text-align:justify;text-justify:inter-ideograph;
page-break-after:auto'><span style='font-family:"Arial",sans-serif'> </span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:.75in;text-align:justify;text-justify:inter-ideograph;
text-indent:-.25in;page-break-after:auto'><span style='font-family:"Arial",sans-serif'>(iii)<span
style='font:7.0pt "Times New Roman"'>&nbsp; </span></span><span
style='font-family:"Arial",sans-serif'>for any other purpose disclosed by us
when you provide the information;</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:.75in;text-align:justify;text-justify:inter-ideograph;
page-break-after:auto'><span style='font-family:"Arial",sans-serif'> </span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:.75in;text-align:justify;text-justify:inter-ideograph;
text-indent:-.25in;page-break-after:auto'><span style='font-family:"Arial",sans-serif'>(iv)<span
style='font:7.0pt "Times New Roman"'>&nbsp; </span></span><span
style='font-family:"Arial",sans-serif'>with your consent in any other cases;</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:.75in;text-align:justify;text-justify:inter-ideograph;
page-break-after:auto'><span style='font-family:"Arial",sans-serif'> </span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:.75in;text-align:justify;text-justify:inter-ideograph;
text-indent:-.25in;page-break-after:auto'><span style='font-family:"Arial",sans-serif'>(v)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span></span><span
style='font-family:"Arial",sans-serif'>if we believe disclosure is necessary or
appropriate to protect the rights, property, or safety of the Company, our
customers, or others.</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:.75in;text-align:justify;text-justify:inter-ideograph;
page-break-after:auto'><span style='font-family:"Arial",sans-serif'> </span></p>

<p class=MsoListParagraphCxSpLast style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-17.85pt'><b><span style='font-family:"Arial",sans-serif'>9.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span></b><b><u><span
style='font-family:"Arial",sans-serif'>Security of Data</span></u></b></p>

<p class=MsoNormal style='margin-bottom:0in;text-align:justify;text-justify:
inter-ideograph'><b><u><span style='font-family:"Arial",sans-serif'> </span></u></b></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:0in;margin-bottom:
0in;margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;
page-break-after:auto'><span style='font-family:"Arial",sans-serif'>The
security of your data is important to us but remember that no method of
transmission over the Internet or method of electronic storage is 100% secure.
While we strive to use commercially acceptable means to protect your Personal
Data, we cannot guarantee its absolute security.</span></p>

<p class=MsoNormal style='margin-bottom:0in;page-break-after:auto'><span
style='font-family:"Arial",sans-serif'> </span></p>

<p class=MsoListParagraphCxSpFirst style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-17.85pt'><b><span style='font-family:"Arial",sans-serif'>10.<span
style='font:7.0pt "Times New Roman"'>&nbsp; </span></span></b><b><u><span
style='font-family:"Arial",sans-serif'>Your Data Protection Rights Under
General Data Protection Regulation (GDPR)</span></u></b></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph'><b><u><span
style='font-family:"Arial",sans-serif'> </span></u></b></p>

<p class=MsoListParagraphCxSpLast style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;
page-break-after:auto'><span style='font-family:"Arial",sans-serif'>If you are
a resident of the European Union (EU) and European Economic Area (EEA), you
have certain data protection rights, covered by GDPR. – See more at </span><a
href="https://eur-lex.europa.eu/eli/reg/2016/679/oj"><span style='font-family:
"Arial",sans-serif'>https://eur-lex.europa.eu/eli/reg/2016/679/oj</span></a><span
style='font-family:"Arial",sans-serif'> </span></p>

<p class=MsoNormal style='margin-bottom:0in;text-align:justify;text-justify:
inter-ideograph;page-break-after:auto'><span style='font-family:"Arial",sans-serif'> </span></p>

<p class=MsoListParagraphCxSpFirst style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;
page-break-after:auto'><span style='font-family:"Arial",sans-serif'>We aim to
take reasonable steps to allow you to correct, amend, delete, or limit the use
of your Personal Data.</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;
page-break-after:auto'><span style='font-family:"Arial",sans-serif'> </span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;
page-break-after:auto'><span style='font-family:"Arial",sans-serif'>If you wish
to be informed what Personal Data we hold about you and if you want it to be
removed from our systems, please email us at support@mist.greenknightlabs.com. </span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;
page-break-after:auto'><span style='font-family:"Arial",sans-serif'> </span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph'><span
style='font-family:"Arial",sans-serif'>In certain circumstances, you have the
following data protection rights:</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph'><span
style='font-family:"Arial",sans-serif'> </span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:35.7pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-17.7pt;page-break-after:auto'><span style='font-family:"Arial",sans-serif'>(a)<span
style='font:7.0pt "Times New Roman"'>&nbsp; </span></span><span
style='font-family:"Arial",sans-serif'>the right to access, update or to delete
the information we have on you;</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:35.7pt;text-align:justify;text-justify:inter-ideograph;
page-break-after:auto'><span style='font-family:"Arial",sans-serif'> </span></p>

<p class=MsoListParagraphCxSpLast style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:35.7pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-17.7pt;page-break-after:auto'><span style='font-family:"Arial",sans-serif'>(b)<span
style='font:7.0pt "Times New Roman"'>&nbsp; </span></span><span
style='font-family:"Arial",sans-serif'>the right of rectification. You have the
right to have your information rectified if that information is inaccurate or
incomplete;</span></p>

<p class=MsoNormal style='margin-bottom:0in;text-align:justify;text-justify:
inter-ideograph;page-break-after:auto'><span style='font-family:"Arial",sans-serif'> </span></p>

<p class=MsoListParagraphCxSpFirst style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:35.7pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-17.7pt;page-break-after:auto'><span style='font-family:"Arial",sans-serif'>(c)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span></span><span
style='font-family:"Arial",sans-serif'>the right to object. You have the right
to object to our processing of your Personal Data;</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:35.7pt;text-align:justify;text-justify:inter-ideograph;
page-break-after:auto'><span style='font-family:"Arial",sans-serif'> </span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:35.7pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-17.7pt;page-break-after:auto'><span style='font-family:"Arial",sans-serif'>(d)<span
style='font:7.0pt "Times New Roman"'>&nbsp; </span></span><span
style='font-family:"Arial",sans-serif'>the right of restriction. You have the
right to request that we restrict the processing of your personal information;</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:35.7pt;text-align:justify;text-justify:inter-ideograph;
page-break-after:auto'><span style='font-family:"Arial",sans-serif'> </span></p>

<p class=MsoListParagraphCxSpLast style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:35.7pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-17.7pt;page-break-after:auto'><span style='font-family:"Arial",sans-serif'>(e)<span
style='font:7.0pt "Times New Roman"'>&nbsp; </span></span><span
style='font-family:"Arial",sans-serif'>the right to data portability. You have
the right to be provided with a copy of your Personal Data in a structured,
machine-readable and commonly used format;</span></p>

<p class=MsoNormal style='margin-bottom:0in;text-align:justify;text-justify:
inter-ideograph;page-break-after:auto'><span style='font-family:"Arial",sans-serif'> </span></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:0in;margin-bottom:
0in;margin-left:35.7pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-17.7pt;page-break-after:auto'><span style='font-family:"Arial",sans-serif'>(f)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><span
style='font-family:"Arial",sans-serif'>the right to withdraw consent.&nbsp;You
also have the right to withdraw your consent at any time where we rely on your
consent to process your personal information;</span></p>

<p class=MsoNormal style='margin-bottom:0in;text-align:justify;text-justify:
inter-ideograph;page-break-after:auto'><span style='font-family:"Arial",sans-serif'> </span></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:0in;margin-bottom:
0in;margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;
page-break-after:auto'><span style='font-family:"Arial",sans-serif'>Please note
that we may ask you to verify your identity before responding to such requests.
Please note, we may not able to provide Service without some necessary data.</span></p>

<p class=MsoNormal style='margin-bottom:0in;text-align:justify;text-justify:
inter-ideograph;page-break-after:auto'><span style='font-family:"Arial",sans-serif'> </span></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:0in;margin-bottom:
0in;margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;
page-break-after:auto'><span style='font-family:"Arial",sans-serif'>You have
the right to complain to a Data Protection Authority about our collection and
use of your Personal Data. For more information, please contact your local data
protection authority in the European Economic Area (EEA).</span></p>

<p class=MsoNormal style='margin-bottom:0in;page-break-after:auto'><span
style='font-family:"Arial",sans-serif'> </span></p>

<p class=MsoListParagraphCxSpFirst style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-17.85pt'><b><span style='font-family:"Arial",sans-serif'>11.<span
style='font:7.0pt "Times New Roman"'>&nbsp; </span></span></b><b><u><span
style='font-family:"Arial",sans-serif'>Your Data Protection Rights under the
California Privacy Protection Act (CalOPPA)</span></u></b></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph'><b><u><span
style='font-family:"Arial",sans-serif'> </span></u></b></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;
page-break-after:auto'><span style='font-family:"Arial",sans-serif'>CalOPPA is
the first state law in the nation to require commercial websites and online
services to post a privacy policy. The law’s reach stretches well beyond
California to require a person or company in the United States (and conceivable
the world) that operates websites collecting personally identifiable
information from California consumers to post a conspicuous privacy policy on
its website stating exactly the information being collected and those
individuals with whom it is being shared, and to comply with this policy. – See
more at: </span><a
href="https://consumercal.org/about-cfc/cfc-education-foundation/california-online-privacy-protection-act-caloppa-3/"><span
style='font-family:"Arial",sans-serif'>https://consumercal.org/about-cfc/cfc-education-foundation/california-online-privacy-protection-act-caloppa-3/</span></a><span
style='font-family:"Arial",sans-serif'> </span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;
page-break-after:auto'><span style='font-family:"Arial",sans-serif'> </span></p>

<p class=MsoListParagraphCxSpLast style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph'><span
style='font-family:"Arial",sans-serif'>According to CalOPPA we agree to the
following:</span></p>

<p class=MsoNormal style='margin-bottom:0in;text-align:justify;text-justify:
inter-ideograph'><span style='font-family:"Arial",sans-serif'> </span></p>

<p class=MsoListParagraphCxSpFirst style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:35.7pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-17.7pt;page-break-after:auto'><span style='font-size:10.0pt;
font-family:"Arial",sans-serif'>(a)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span style='font-family:"Arial",sans-serif'>users can visit our
site anonymously;</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:35.7pt;text-align:justify;text-justify:inter-ideograph;
page-break-after:auto'><span style='font-family:"Arial",sans-serif'> </span></p>

<p class=MsoListParagraphCxSpLast style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:35.7pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-17.7pt;page-break-after:auto'><span style='font-size:10.0pt;
font-family:"Arial",sans-serif'>(b)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span style='font-family:"Arial",sans-serif'>our Privacy Policy
link includes the word “Privacy”, and can easily be found on the page specified
above on the home page of our website;</span></p>

<p class=MsoNormal style='margin-bottom:0in;text-align:justify;text-justify:
inter-ideograph;page-break-after:auto'><span style='font-family:"Arial",sans-serif'> </span></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:0in;margin-bottom:
0in;margin-left:35.7pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-17.7pt;page-break-after:auto'><span style='font-size:10.0pt;
font-family:"Arial",sans-serif'>(c)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span style='font-family:"Arial",sans-serif'>users will be
notified of any privacy policy changes on our Privacy Policy Page;</span></p>

<p class=MsoNormal style='margin-bottom:0in;text-align:justify;text-justify:
inter-ideograph;page-break-after:auto'><span style='font-family:"Arial",sans-serif'> </span></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:0in;margin-bottom:
0in;margin-left:35.7pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-17.7pt;page-break-after:auto'><span style='font-size:10.0pt;
font-family:"Arial",sans-serif'>(d)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span style='font-family:"Arial",sans-serif'>users are able to
change their personal information by emailing us at
support@mist.greenknightlabs.com. </span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:.25in;text-align:justify;text-justify:inter-ideograph;page-break-after:
auto'><span style='font-family:"Arial",sans-serif'> </span></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:0in;margin-bottom:
0in;margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph'><span
style='font-family:"Arial",sans-serif'>Our Policy on “Do Not Track” Signals:</span></p>

<p class=MsoNormal style='margin-bottom:0in;text-align:justify;text-justify:
inter-ideograph'><span style='font-family:"Arial",sans-serif'> </span></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:0in;margin-bottom:
0in;margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph'><span
style='font-family:"Arial",sans-serif'>We honor Do Not Track signals and do not
track, plant cookies, or use advertising when a Do Not Track browser mechanism
is in place. Do Not Track is a preference you can set in your web browser to
inform websites that you do not want to be tracked. </span></p>

<p class=MsoNormal style='margin-bottom:0in;text-align:justify;text-justify:
inter-ideograph'><span style='font-family:"Arial",sans-serif'> </span></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:0in;margin-bottom:
0in;margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph'><span
style='font-family:"Arial",sans-serif'>You can enable or disable Do Not Track
by visiting the Preferences or Settings page of your web browser.</span></p>

<p class=MsoNormal style='margin-bottom:0in;page-break-after:auto'><span
style='font-family:"Arial",sans-serif'> </span></p>

<p class=MsoListParagraphCxSpFirst style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-17.85pt'><b><span style='font-family:"Arial",sans-serif'>12.<span
style='font:7.0pt "Times New Roman"'>&nbsp; </span></span></b><b><u><span
style='font-family:"Arial",sans-serif'>Service Providers</span></u></b></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph'><b><u><span
style='font-family:"Arial",sans-serif'> </span></u></b></p>

<p class=MsoListParagraphCxSpLast style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;
page-break-after:auto'><span style='font-family:"Arial",sans-serif'>We may
employ third party companies and individuals to facilitate our Service (“<b>Service
Providers</b>”), provide Service on our behalf, perform Service-related
services or assist us in analysing how our Service is used.</span></p>

<p class=MsoNormal style='margin-bottom:0in;text-align:justify;text-justify:
inter-ideograph;page-break-after:auto'><span style='font-family:"Arial",sans-serif'> </span></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:0in;margin-bottom:
0in;margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;
page-break-after:auto'><span style='font-family:"Arial",sans-serif'>These third
parties have access to your Personal Data only to perform these tasks on our
behalf and are obligated not to disclose or use it for any other purpose.</span></p>

<p class=MsoNormal style='margin-bottom:0in;page-break-after:auto'><span
style='font-family:"Arial",sans-serif'> </span></p>

<p class=MsoListParagraphCxSpFirst style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-17.85pt'><b><span style='font-family:"Arial",sans-serif'>13.<span
style='font:7.0pt "Times New Roman"'>&nbsp; </span></span></b><b><u><span
style='font-family:"Arial",sans-serif'>Analytics</span></u></b></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph'><b><u><span
style='font-family:"Arial",sans-serif'> </span></u></b></p>

<p class=MsoListParagraphCxSpLast style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph'><span
style='font-family:"Arial",sans-serif'>We may use third-party Service Providers
to monitor and analyze the use of our Service.</span></p>

<p class=MsoNormal style='margin-bottom:0in;text-align:justify;text-justify:
inter-ideograph'><span style='font-family:"Arial",sans-serif'> </span></p>

<p class=MsoSubtitle style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph'><span
style='font-family:"Arial",sans-serif;letter-spacing:0pt'>Google Analytics</span></p>

<p class=MsoListParagraphCxSpFirst style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;
page-break-after:auto'><span style='font-family:"Arial",sans-serif'>Google
Analytics is a web analytics service offered by Google that tracks and reports
website traffic. Google uses the data collected to track and monitor the use of
our Service. This data is shared with other Google services. Google may use the
collected data to contextualise and personalise the ads of its own advertising
network.</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;
page-break-after:auto'><span style='font-family:"Arial",sans-serif'> </span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;
page-break-after:auto'><span style='font-family:"Arial",sans-serif'>For more
information on the privacy practices of Google, please visit the Google Privacy
Terms web page: </span><a href="https://policies.google.com/privacy?hl=en"><span
style='font-family:"Arial",sans-serif'>https://policies.google.com/privacy?hl=en</span></a></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;
page-break-after:auto'><span class=MsoHyperlink><span style='font-family:"Arial",sans-serif'> </span></span></p>

<p class=MsoListParagraphCxSpLast style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;
page-break-after:auto'><span style='font-family:"Arial",sans-serif'>We also
encourage you to review the Google's policy for safeguarding your data: </span><a
href="https://support.google.com/analytics/answer/6004245"><span
style='font-family:"Arial",sans-serif'>https://support.google.com/analytics/answer/6004245</span></a><span
style='font-family:"Arial",sans-serif'>.</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;page-break-after:
auto'><span style='font-family:"Arial",sans-serif'> </span></p>

<p class=MsoListParagraphCxSpFirst style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph'><b><span
style='font-family:"Arial",sans-serif'>Firebase</span></b></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;
page-break-after:auto'><span style='font-family:"Arial",sans-serif'>Firebase is
analytics service provided by Google Inc.</span></p>

<p class=MsoListParagraphCxSpLast style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;
page-break-after:auto'><span style='font-family:"Arial",sans-serif'> </span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;page-break-after:
auto'><span style='font-family:"Arial",sans-serif'>You may opt-out of certain
Firebase features through your mobile device settings, such as your device
advertising settings or by following the instructions provided by Google in
their Privacy Policy: </span><a href="https://policies.google.com/privacy?hl=en"><span
style='font-family:"Arial",sans-serif'>https://policies.google.com/privacy?hl=en</span></a></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;page-break-after:
auto'><span class=MsoHyperlink><span style='font-family:"Arial",sans-serif'> </span></span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;page-break-after:
auto'><span style='font-family:"Arial",sans-serif'>For more information on what
type of information Firebase collects, please visit the Google Privacy Terms
web page: </span><a href="https://policies.google.com/privacy?hl=en"><span
style='font-family:"Arial",sans-serif'>https://policies.google.com/privacy?hl=en</span></a></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;page-break-after:
auto'><span class=MsoHyperlink><span style='font-family:"Arial",sans-serif'> </span></span></p>

<p class=MsoListParagraphCxSpFirst style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-17.85pt'><b><span style='font-family:"Arial",sans-serif'>14.<span
style='font:7.0pt "Times New Roman"'>&nbsp; </span></span></b><b><u><span
style='font-family:"Arial",sans-serif'>CI/CD tools</span></u></b></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph'><span
style='font-family:"Arial",sans-serif'> </span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph'><span
style='font-family:"Arial",sans-serif'>We may use third-party Service Providers
to automate the development process of our Service. </span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph'><b><span
style='font-family:"Arial",sans-serif'> </span></b></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph'><b><span
style='font-family:"Arial",sans-serif'>GitHub</span></b></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph'><span
style='font-family:"Arial",sans-serif'> </span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;
page-break-after:auto'><span style='font-family:"Arial",sans-serif'>GitHub is
provided by GitHub, Inc.</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;
page-break-after:auto'><span style='font-family:"Arial",sans-serif'> </span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;
page-break-after:auto'><span style='font-family:"Arial",sans-serif'>GitHub is a
development platform to host and review code, manage projects, and build
software.</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;
page-break-after:auto'><span style='font-family:"Arial",sans-serif'> </span></p>

<p class=MsoListParagraphCxSpLast style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;
page-break-after:auto'><span style='font-family:"Arial",sans-serif'>For more
information on what data GitHub collects for what purpose and how the
protection of the data is ensured, please visit GitHub Privacy Policy page:<u><span
style='color:blue'> </span></u></span><a
href="https://help.github.com/en/articles/github-privacy-statement"><span
style='font-family:"Arial",sans-serif'>https://help.github.com/en/articles/github-privacy-statement</span></a><span
style='font-family:"Arial",sans-serif'>.</span></p>

<p class=MsoNormal style='margin-bottom:0in;page-break-after:auto'><b><span
style='font-family:"Arial",sans-serif;background:lime'> </span></b></p>

<p class=MsoListParagraphCxSpFirst style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-17.85pt'><b><span style='font-family:"Arial",sans-serif'>15.<span
style='font:7.0pt "Times New Roman"'>&nbsp; </span></span></b><b><u><span
style='font-family:"Arial",sans-serif'>Payments</span></u></b></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph'><b><u><span
style='font-family:"Arial",sans-serif'> </span></u></b></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;
page-break-after:auto'><span style='font-family:"Arial",sans-serif'>We may
provide paid products and/or services within Service. In that case, we use
third-party services for payment processing (e.g. payment processors).</span></p>

<p class=MsoListParagraphCxSpLast style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;
page-break-after:auto'><span style='font-family:"Arial",sans-serif'> </span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;page-break-after:
auto'><span style='font-family:"Arial",sans-serif'>We will not store or collect
your payment card details. That information is provided directly to our
third-party payment processors whose use of your personal information is
governed by their Privacy Policy. These payment processors adhere to the
standards set by PCI-DSS as managed by the PCI Security Standards Council,
which is a joint effort of brands like Visa, Mastercard, American Express and
Discover. PCI-DSS requirements help ensure the secure handling of payment
information.</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;page-break-after:
auto'><span style='font-family:"Arial",sans-serif'> </span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph'><span
style='font-family:"Arial",sans-serif'>The payment processors we work with are:</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;page-break-after:auto'><span style='font-family:"Arial",sans-serif'> </span></p>

<p class=MsoSubtitle style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt'><span style='font-family:"Arial",sans-serif;letter-spacing:
0pt'>Apple Store In-App Payments:</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;page-break-after:auto'><span style='font-family:"Arial",sans-serif'>Their
Privacy Policy can be viewed at: </span><a
href="https://www.apple.com/legal/privacy/en-ww/"><span style='font-family:
"Arial",sans-serif'>https://www.apple.com/legal/privacy/en-ww/</span></a><span
style='font-family:"Arial",sans-serif'> / </span><a
href="https://support.apple.com/en-us/HT203027"><span style='font-family:"Arial",sans-serif'>https://support.apple.com/en-us/HT203027</span></a><span
style='font-family:"Arial",sans-serif'> </span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;page-break-after:auto'><span style='font-family:"Arial",sans-serif'> </span></p>

<p class=MsoSubtitle style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt'><span style='font-family:"Arial",sans-serif;letter-spacing:
0pt'>Google Play In-App Payments:</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;page-break-after:auto'><span style='font-family:"Arial",sans-serif'>Their
Privacy Policy can be viewed at: </span><a
href="https://policies.google.com/privacy?hl=en&amp;gl=us"><span
style='font-family:"Arial",sans-serif'>https://policies.google.com/privacy?hl=en&amp;gl=us</span></a><span
style='font-family:"Arial",sans-serif'> / </span><a
href="https://payments.google.com/payments/apis-secure/u/0/get_legal_document?ldo=0&amp;ldt=privacynotice&amp;ldl=en"><span
style='font-family:"Arial",sans-serif'>https://payments.google.com/payments/apis-secure/u/0/get_legal_document?ldo=0&amp;ldt=privacynotice&amp;ldl=en</span></a><span
style='font-family:"Arial",sans-serif'> </span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;page-break-after:auto'><span style='font-family:"Arial",sans-serif'> </span></p>

<p class=MsoListParagraphCxSpFirst style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-17.85pt'><b><span style='font-family:"Arial",sans-serif'>16.<span
style='font:7.0pt "Times New Roman"'>&nbsp; </span></span></b><b><u><span
style='font-family:"Arial",sans-serif'>Links to Other Sites</span></u></b></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph'><b><u><span
style='font-family:"Arial",sans-serif'> </span></u></b></p>

<p class=MsoListParagraphCxSpLast style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;
page-break-after:auto'><span style='font-family:"Arial",sans-serif'>Our Service
may contain links to other sites that are not operated by us. If you click a
third party link, you will be directed to that third party's site. We strongly
advise you to review the Privacy Policy of every site you visit.</span></p>

<p class=MsoNormal style='margin-bottom:0in;text-align:justify;text-justify:
inter-ideograph;page-break-after:auto'><b><u><span style='font-family:"Arial",sans-serif'> </span></u></b></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;page-break-after:
auto'><span style='font-family:"Arial",sans-serif'>We have no control over and
assume no responsibility for the content, privacy policies or practices of any
third party sites or services.</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;page-break-after:auto'><span style='font-family:"Arial",sans-serif'> </span></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:0in;margin-bottom:
0in;margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-17.85pt'><b><span style='font-family:"Arial",sans-serif'>17.<span
style='font:7.0pt "Times New Roman"'>&nbsp; </span></span></b><b><u><span
style='font-family:"Arial",sans-serif'>Children's Privacy</span></u></b></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:.25in;text-align:justify;text-justify:inter-ideograph'><span
style='font-family:"Arial",sans-serif'> </span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:.25in;text-align:justify;text-justify:inter-ideograph'><span
style='font-family:"Arial",sans-serif'>Our Services are not intended for use by
children under the age of 13 (“<b>Children</b>”). </span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:.25in;text-align:justify;text-justify:inter-ideograph'><span
style='font-family:"Arial",sans-serif'> </span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:.25in;text-align:justify;text-justify:inter-ideograph'><span
style='font-family:"Arial",sans-serif'>We do not knowingly collect personally
identifiable information from Children under 13. If you become aware that a
Child has provided us with Personal Data, please contact us. If we become aware
that we have collected Personal Data from Children without verification of
parental consent, we take steps to remove that information from our servers.</span></p>

<p class=MsoListParagraphCxSpFirst style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph'><span
style='font-family:"Arial",sans-serif'> </span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-17.85pt'><b><span style='font-family:"Arial",sans-serif'>18.<span
style='font:7.0pt "Times New Roman"'>&nbsp; </span></span></b><b><u><span
style='font-family:"Arial",sans-serif'>Changes to This Privacy Policy</span></u></b></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph'><b><u><span
style='font-family:"Arial",sans-serif'> </span></u></b></p>

<p class=MsoListParagraphCxSpLast style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;
page-break-after:auto'><span style='font-family:"Arial",sans-serif'>We may
update our Privacy Policy from time to time. We will notify you of any changes
by posting the new Privacy Policy on this page.</span></p>

<p class=MsoNormal style='margin-bottom:0in;text-align:justify;text-justify:
inter-ideograph;page-break-after:auto'><b><u><span style='font-family:"Arial",sans-serif'> </span></u></b></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:0in;margin-bottom:
0in;margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;
page-break-after:auto'><span style='font-family:"Arial",sans-serif'>We will let
you know via email and/or a prominent notice on our Service, prior to the
change becoming effective and update “effective date” at the top of this
Privacy Policy.</span></p>

<p class=MsoNormal style='margin-bottom:0in;text-align:justify;text-justify:
inter-ideograph;page-break-after:auto'><span style='font-family:"Arial",sans-serif'> </span></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:0in;margin-bottom:
0in;margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;
page-break-after:auto'><span style='font-family:"Arial",sans-serif'>You are
advised to review this Privacy Policy periodically for any changes. Changes to
this Privacy Policy are effective when they are posted on this page.</span></p>

<p class=MsoNormal style='margin-bottom:0in;page-break-after:auto'><span
style='font-family:"Arial",sans-serif'> </span></p>

<p class=MsoListParagraphCxSpFirst style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-17.85pt'><b><span style='font-family:"Arial",sans-serif'>19.<span
style='font:7.0pt "Times New Roman"'>&nbsp; </span></span></b><b><u><span
style='font-family:"Arial",sans-serif'>Contact Us</span></u></b></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph'><b><u><span
style='font-family:"Arial",sans-serif'> </span></u></b></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph'><span
style='font-family:"Arial",sans-serif'>If you have any questions about this
Privacy Policy, please contact us:</span></p>

<p class=MsoListParagraphCxSpLast style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:17.85pt;text-align:justify;text-justify:inter-ideograph'><span
style='font-family:"Arial",sans-serif'> </span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;page-break-after:auto'><span style='font-family:"Arial",sans-serif'>By
email: support@mist.greenknightlabs.com.</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;page-break-after:auto'><span style='font-family:"Arial",sans-serif'> </span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;page-break-after:auto'><span style='font-family:"Arial",sans-serif'>By
visiting this page on our website: https://web.mist.greenknightlabs.com/contact.</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:17.85pt;page-break-after:auto'><span style='font-family:"Arial",sans-serif'> </span></p>

</div>

</body>

</html>
    `;

    return (
        <Box sx={{ bgcolor: 'white' }} p={2}>
            <div style={{ color: 'black' }} dangerouslySetInnerHTML={{ __html: html }} />
        </Box>
    );
}
